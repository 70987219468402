import userApi from '../api/user';
import $ from 'jquery';
import {push} from 'connected-react-router';

export function getResetToken(){
    return { type:'PASSWORD_SET_SUCCESS' };
}

export function getResetPasswordToken(){

    return function (dispatch) {
            return dispatch(getResetToken());
    };
}

export function RedirectToLogin(){
    return function (dispatch) {
        dispatch (push('/login')); 
    };
}