import equApi from '../api/equipement';
import transformData from '../api/utility';
import $ from "jquery";
import {push} from "connected-react-router";

export function clearState() {
    return {type: 'CLEAR'};
}

export function addInterval(data){
    return function (dispatch) {
        return dispatch({ type:'INTERVAL', data });
    }
}

export function clearInterval(){
    return function (dispatch) {
        return dispatch({ type:'CLEAR_INTERVAL', data:null });
    }
}

export function setIntervalOne(data) {
    return {type: 'SETINTERVAL', data};
}

export function setIntervalTwo(data) {
    return {type: 'SETINTERVAL2', data};
}

export function viewMotorAnalysis(motorAnalysis) {
    motorAnalysis = transformData.transformNumericValue(motorAnalysis);
    return function (dispatch) {
        if (motorAnalysis.equipmentType === "UtilityMain" || motorAnalysis.equipmentType === "Rectifier") {
            dispatch ({type: 'VIEW_MOTOR_ANALYSIS', motorAnalysis});
            dispatch (push('/power-analysis') )
        } else {
            dispatch ({type: 'VIEW_MOTOR_ANALYSIS', motorAnalysis});
            dispatch (push('/motor-analysis') )
        }
    };
}

export function refMotorAnalysis(motorAnalysis) {
    motorAnalysis = transformData.transformNumericValue(motorAnalysis);
    return {type: 'REF_MOTOR_ANALYSIS', motorAnalysis};
}

export function viewMotorAnalysisMain(motorAnalysis) {
    motorAnalysis = transformData.transformNumericValue(motorAnalysis);
    return function (dispatch) {
        if (motorAnalysis.equipmentType === "UtilityMain" || motorAnalysis.equipmentType === "Rectifier") {
            dispatch ({type: 'VIEW_MOTOR_ANALYSIS_MAIN', motorAnalysis});
            dispatch (push('/power-analysis') )
        } else {
            dispatch ({type: 'VIEW_MOTOR_ANALYSIS_MAIN', motorAnalysis});
            dispatch (push('/motor-analysis') )
        }
    };
}

export function refMotorAnalysisMain(motorAnalysis) {
    motorAnalysis = transformData.transformNumericValue(motorAnalysis);
    return {type: 'REF_MOTOR_ANALYSIS_MAIN', motorAnalysis};
}

export function loadMotorAnalysis(serialNumber) {
    return function (dispatch, getState) {

        setTimeout(
            function () {
                let refCall = false;
                let token = getState().application.token;

                if (serialNumber === undefined) {
                    refCall = true;
                    serialNumber = getState().application.selectedSerialNumber;
                    if(serialNumber === null || serialNumber === undefined || serialNumber.length === 0)
                    {
                        dispatch({type: 'LOAD_EQUIPMENT_PORTFOLIO'});
                    }
                }
                return equApi.getMotorAnalysis(token, serialNumber).then(data => {

                    console.log('Data', data.length);
                    console.log('Actual Data', data);

                    if(data.name === null || data.name === undefined){
                        $('.loaderPosition').addClass('hidden');
                        var error = {message: "NO DATA AVAILABLE", timestamp: Math.floor(Date.now() / 1000)}
                        return dispatch({type: 'ON_ERROR_OCCURRED', error});
                    }

                    if (refCall === false) {
                        data["serialNumberToDisplay"] = getState().application.selectedName;
                        dispatch(viewMotorAnalysis(data));
                    } else {
                        data["serialNumberToDisplay"] = getState().application.selectedName;
                        dispatch(refMotorAnalysis(data));
                    }
                }).catch(error => {
                    if (error === undefined || error.httpStatusCode === 405) {
                        dispatch({type: 'LOAD_LOGIN'});
                    }
                });
            }, 0);
    };
}

export function loadMotorAnalysisMain(serialNumber) {
    return function (dispatch, getState) {

        setTimeout(
            function () {
                let refCall = false;
                let token = getState().application.token;

                if (serialNumber === undefined) {
                    refCall = true;
                    serialNumber = getState().application.selectedSerialNumber;
                    if(serialNumber === null || serialNumber === undefined || serialNumber.length === 0)
                    {
                        dispatch({type: 'LOAD_EQUIPMENT_PORTFOLIO'});
                    }
                }
                return equApi.getMotorAnalysis(token, serialNumber).then(data => {

                    console.log('Data', data.length);
                    console.log('Actual Data', data);

                    if(data.name === null || data.name === undefined){
                        $('.loaderPosition').addClass('hidden');
                        var error = {message: "NO DATA AVAILABLE", timestamp: Math.floor(Date.now() / 1000)}
                        return dispatch({type: 'ON_ERROR_OCCURRED', error});
                    }

                    if (refCall === false) {
                        data["serialNumberToDisplay"] = getState().application.selectedName;
                        dispatch(viewMotorAnalysisMain(data));
                    } else {
                        data["serialNumberToDisplay"] = getState().application.selectedName;
                        dispatch(refMotorAnalysisMain(data));
                    }
                }).catch(error => {
                    if (error === undefined || error.httpStatusCode === 405) {
                        dispatch({type: 'LOAD_LOGIN'});
                    }
                });
            }, 0);
    };
}

export function RedirectToEquipmentPortfolio(){

    return function (dispatch) {
        dispatch({type: 'LOAD_EQUIPMENT_PORTFOLIO'});
    };
}