import React from 'react'
import { Route, Redirect } from 'react-router-dom'


export default function ProtectedRoute({refreshExp, children, ...rest }) {
    

  return (
    <Route {...rest}>
                {refreshExp < new Date()  
                    ?
                    <Redirect to="/login" /> 
                    : 
                    children}
        </Route>
  )
}
