import { combineReducers } from 'redux';
import application from './applicationReducers';
import { connectRouter } from 'connected-react-router'

const rootReducer = (history) =>  combineReducers({
  router: connectRouter(history),
  application,
});

export default rootReducer;
