import React from 'react';

export default class Mapping extends React.Component{
    render() {
        return(
            <div className=" selectDataH clearfix">
                <div className="selectContent">
                    <span className="selectLabel">Select Node</span>
                    <select
                        onChange={this.props.handleChangeOnNode} >
                        {
                            this.props.listOFAllEquipment.map(equipment => {
                                if (this.props.selectedNode === equipment.name) {
                                    return (<option value={JSON.stringify(equipment)}
                                                    selected>{equipment.name}</option>);
                                } else {
                                    return (<option
                                        value={JSON.stringify(equipment)}>{equipment.name}</option>);
                                }

                            })
                        }
                    </select>
                </div>
                <div className="selectContent">
                    <span className="selectLabel">Select Data</span>
                    <select
                        onChange={this.props.handleChangeOnData}>
                        {
                            this.props.listOfAllDataPoints.map((dp, i) => {
                                if (this.props.selectedDataPoint.name === dp.name) {
                                    return (<option key={i} value={JSON.stringify(dp)}
                                                    selected>{dp.name}</option>);
                                }else{
                                    return (<option key={i}
                                                    value={JSON.stringify(dp)}>{dp.name}</option>);
                                }
                            })
                        }
                    </select>
                </div>
            </div>
        )
    }
}