import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import userApi from '../../api/user';
import $ from 'jquery';
import * as userAction from '../../actions/recoverPassword';


class ResetPasswordPage extends React.Component {

    static getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    constructor(props, context) {
        super(props, context);
        this.data = {};
    }

    update = (event) => {
        const field = event.target.name;
        let user = this.data;
        user[field] = event.target.value;

        if (user['code'] === undefined && this.props.state.user.defaultCode) {
            user['code'] = this.props.state.user.defaultCode;
        }
    }


    resetPassword = () => {

        if (this.data.newPassword === null || this.data.newPassword === undefined || this.data.newPassword.trim().length < 1) {
            this.refs.container.info(
                "",
                "Please enter password!", {
                timeOut: 5000,
                preventDuplicates: true,
                closeButton: true
            });
            return;
        }

        if (this.data.confirmedPassword === null || this.data.confirmedPassword === undefined || this.data.confirmedPassword.trim().length < 1) {
            this.refs.container.info(
                "",
                "Please confirm password!", {
                timeOut: 5000,
                preventDuplicates: true,
                closeButton: true
            });
            return;
        }

        if (this.data.newPassword !== this.data.confirmedPassword) {

            this.refs.container.info(
                "",
                "Password is not matching", {
                timeOut: 5000,
                preventDuplicates: true,
                closeButton: true
            });
            return;
        }

        $('.loaderPosition').removeClass('hidden');

        userApi.resetPassword(this.data.code, this.data.newPassword).then(data => {
            this.refs.container.success(
                "",
                "Password is Set Successfully!", {
                timeOut: 5000,
                preventDuplicates: true,
                closeButton: true
            });


            this.props.action.getResetPasswordToken();

            setTimeout(() => {
                this.props.action.RedirectToLogin();
                $('.loaderPosition').addClass('hidden');
            }, 3000);

        }).catch(error => {

            this.refs.container.info(
                "",
                error, {
                timeOut: 5000,
                preventDuplicates: true,
                closeButton: true
            });
            $('.loaderPosition').addClass('hidden');
        });

    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.resetPassword();
        }
    }

    render() {

        let ReactToastr = require("react-toastr");
        let { ToastContainer } = ReactToastr;
        let ToastMessageFactory = React.createFactory(ReactToastr.ToastMessage.animation);

        const OnLoginDetailKeypress = (event) => { this.handleKeyPress(event); };
        const OnUserDetailsChange = (event) => { this.update(event); };

        return (
            <div className="loginPage">

                <ToastContainer ref="container"
                    toastMessageFactory={ToastMessageFactory}
                    className="toast-top-right" />

                <div className="wrapperH" onKeyPress={OnLoginDetailKeypress}>
                    <div className="loginH">
                        <div className="logoBigH">
                            <Link to="login" />
                        </div>
                    </div>
                    <div className="loginDetails" >
                        {/* --removed from UI (there is no need to show token to the customer)
                        
                        <div className="uNameH mb20">
                            <span className="labelH">Recovery Code:</span>
                            <input type="text" name="code" className="inputBox bdRadius" defaultValue={this.props.state.user.defaultCode} value={this.props.state.user.code} onChange={OnUserDetailsChange} />
                            <span className="subLabels">Please check your email and enter your Recovery Code here.</span>
                        </div>
                        */}
                        <div className="uNameH mb20">
                            <span className="labelH">Enter New Password:</span>
                            <input type="password" name="newPassword" className="inputBox bdRadius" onChange={OnUserDetailsChange} />
                        </div>
                        <div className="uNameH mb20">
                            <span className="labelH">Confirm New Password:</span>
                            <input type="password" name="confirmedPassword" className="inputBox bdRadius" onChange={OnUserDetailsChange} />
                        </div>
                        <div className="continueBtnH">
                            <input type="button" value="Continue" className="continueBtn bdRadius" onClick={this.resetPassword} />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return { state: { user: { defaultCode: ResetPasswordPage.getParameterByName('reset') } } };
}

const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators(userAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
