import {push} from 'connected-react-router';

export function addInterval(data){
    return function (dispatch) {
        return dispatch({ type:'INTERVAL', data });
    }
}

export function clearInterval(){
    return function (dispatch) {
        return dispatch({ type:'CLEAR_INTERVAL', data:null });
    }
}

export function validateUser(data){
    return function (dispatch) {
        dispatch({ type:'LOGIN_SUCCESS', data });
        setTimeout( () => dispatch (push('/equipment-portfolio')), 0 )
    };
}

export function RedirectToLogin(){

    return function (dispatch) {
        dispatch({type: 'LOAD_LOGIN'});
    };
}

export function updateToken() {
    return function (dispatch) {
        dispatch( {type: 'UPDATE_TOKENS'})
    }
}