import React from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import userApi from '../../api/user';
import $ from 'jquery';
import * as userAction from '../../actions/recoverPassword';

import './recoverPassword.css'

class RecoverPasswordPage extends React.Component {
    updateUser = (event) =>{
        this.resetEmailId = event.target.value;
    }

    getPasswordResetToken = () => {

        if(this.resetEmailId === null || this.resetEmailId === undefined || this.resetEmailId.trim().length < 1) {
            this.refs.container.info(
                "",
                "Please provide valid email address", {
                    timeOut: 5000,
                    preventDuplicates: true,
                    closeButton: true
                });
            return;
        }

        $('.loaderPosition').removeClass('hidden');

        userApi.getResetToken(this.resetEmailId).then(data =>{

            this.refs.container.success(
                "",
                "Please check your mail for reset password link", {
                    timeOut: 5000,
                    preventDuplicates:true,
                    closeButton:true
                });
            $('.loaderPosition').addClass('hidden');

        }).catch(error => {

            this.refs.container.info(
                "",
                "Provided email address not found", {
                    timeOut: 5000,
                    preventDuplicates:true,
                    closeButton:true
                });
            $('.loaderPosition').addClass('hidden');
        });
    }

    handleKeyPress(event) {
        if(event.key === 'Enter'){
            this.getPasswordResetToken();
        }
    }

    render() {

        let ReactToastr = require("react-toastr");
        let {ToastContainer} = ReactToastr;
        let ToastMessageFactory = React.createFactory(ReactToastr.ToastMessage.animation);

        const OnLoginDetailKeypress = (event) => {this.handleKeyPress(event);};
        const OnUserChange = (event) => {this.updateUser(event);};

        return (
            <div className="loginPage">

                <ToastContainer ref="container"
                                toastMessageFactory={ToastMessageFactory}
                                className="toast-top-right" />

                <div className="wrapperH" onKeyPress={OnLoginDetailKeypress}>
                    <div className="loginH">
                        <div className="logoBigH">
                            <Link to="login"/>
                        </div>
                    </div>
                    <div className="loginDetails"  >
                        <div className="uNameH mb20">
                            <span className="labelH">Enter your email address: </span>
                            <input type="text" className="inputBox bdRadius" name="resetEmailId" value={this.props.state.resetEmailId} onChange={OnUserChange}  />
                            {/*  <span className="subLabels">Recover your Password</span>*/}
                            <text className='supportLink'>For additional help email: &nbsp; </text>
                            <a href='mailto:support@voltainsite.com' className='supportEmail'>support@voltainsite.com</a>
                        
                        </div>
                        <div className="recoverAlert">
                            <span className="subLabels">Request an email with reset password link.</span>
                        </div>
                       
                        <div className="continueBtnH">
                            <input type="button" value="Continue" className="continueBtn bdRadius" onClick={this.getPasswordResetToken} />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {state: state};
}

const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators(userAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordPage);
