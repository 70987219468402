import $ from 'jquery';
import config from './config';

class userApi {

    static login(userName, passWord, duration){
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + "sessions",
                method: "POST",
                data: JSON.stringify({"username": userName, "password": passWord , duration:duration}),
                dataType: "json",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${config.externalToken}`
                }

            });

            request.done(function( msg ) {
                resolve(Object.assign([], msg));
            });

            request.fail(function( jqXHR, textStatus ) {
                if(jqXHR.responseJSON !== undefined && jqXHR.responseJSON !== null) {
                    reject(jqXHR.responseJSON.errorMessage);
                }
                else {
                    reject("Unknown error");
                }
            });

        });
    }

    static patchNewToken(sessionToken, refreshToken, duration){
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + "sessions",
                method: "PATCH",
                dataType: "json",
                data: JSON.stringify({ refreshToken: refreshToken, duration:duration}),
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${sessionToken}`
                }

            });

            request.done(function( msg ) {
                resolve(Object.assign([], msg));
            });

            request.fail(function( jqXHR, textStatus ) {
                if(jqXHR.responseJSON !== undefined && jqXHR.responseJSON !== null) {
                    reject(jqXHR.responseJSON.errorMessage);
                }
                else {
                    reject("Unknown error");
                }
            });

        });
    }

    static logout(sessionToken){
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + "sessions",
                method: "DELETE",
                dataType: "json",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${sessionToken}`
                }

            });

            request.done(function( msg ) {
                resolve(Object.assign([], msg));
            });

            request.fail(function( jqXHR, textStatus ) {
                if(jqXHR.responseJSON !== undefined && jqXHR.responseJSON !== null) {
                    reject(jqXHR.responseJSON.errorMessage);
                }
                else {
                    reject("Unknown error");
                }
            });

        });
    }

    static getResetToken(userName){

        return new Promise((resolve, reject) => {

            let request = $.ajax({
                url: config.baseURL + "users/resettoken",
                method: "POST",
                data: JSON.stringify({"email": userName, "urlTemplate": config.applicationUrlForResetPassword}),
                dataType: "json",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${config.externalToken}`
                }

            });

            request.done(function( msg ) {
                resolve(Object.assign({}, msg));
            });

            request.fail(function( jqXHR, textStatus ) {
                if(jqXHR.responseJSON !== undefined && jqXHR.responseJSON !== null) {
                    reject(jqXHR.responseJSON.errorMessage);
                }
                else {
                    reject("Unknown error");
                }
            });

        });
    }

    static resetPassword(resetToken, password){

        return new Promise((resolve, reject) => {

            let request = $.ajax({
                url: config.baseURL + "users/password",
                method: "PATCH",
                data: JSON.stringify({"resetToken": resetToken, "password": password}),
                dataType: "json",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${config.externalToken}`
                }

            });

            request.done(function( msg ) {
                resolve(Object.assign([], msg));
            });

            request.fail(function( jqXHR, textStatus ) {
                if(jqXHR.responseJSON !== undefined && jqXHR.responseJSON !== null) {
                    reject(jqXHR.responseJSON.errorMessage);
                }
                else {
                    reject("Un-know error");
                }
            });

        });
    }
}

export default userApi;
