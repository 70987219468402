import * as d3 from "d3";
import * as fc from "d3fc"
export default function BuildBarChart(_data, chartID, navigatorChartID, getAllSyncChart, defaultSamplingSize, afterHowManyDaysWillShowAllDataPoint, defaultBrushRange, intervalGapInDatPoint) {

  _data['xDataAttribute'] = 'date';
  _data['yDataAttribute'] = 'temperature';
  //console.log("_data_data_data_data_data", _data)
  var globalChartInstance = null;

  var lastBrushSelectionEvent = null;

  function getSampler(samplerSize) {
    var sampler = fc.largestTriangleThreeBucket();

    // Configure the x / y value accessors
    sampler.x(function (d) { return d[_data.xDataAttribute]; })
      .y(function (d) { return d[_data.yDataAttribute]['value']; });

    // Size of buck need to define dynamically according
    // to number of record need to render on UI
    sampler.bucketSize(samplerSize);
    return sampler;
  }

  let height = 250;
  // add the X gridlines

  // gridlines in x axis function
  function make_x_gridlines() {
    return d3.axisBottom(x)
  }
  //removing old gridlines when chart is updating 
  function removeOld_x_gridlines() {
    d3.select(chartID).selectAll('.grid').remove();
    return d3.axisBottom(x)
  }

  let fullSeriesData = [];

  function generateSamplerData() {
    var _fullSeriesData = [];
    _data.series.forEach(function (value) {
      _fullSeriesData.push(value);
    });

    fullSeriesData = _fullSeriesData;
    return _fullSeriesData;
  }
  generateSamplerData(defaultSamplingSize);

  const yExtent = function (xLeft, xRight, fullData) {
    let linerExtender = fc.extentLinear()
      .accessors([d => {
        if (xLeft && xRight) {
          if (d[_data.xDataAttribute] >= xLeft && d[_data.xDataAttribute] <= xRight) {
            return d[_data.yDataAttribute]['value']
          }
        } else {
          return d[_data.yDataAttribute]['value']
        }
      }])
      .pad([0.02, 0.4]);
    return linerExtender(fullData);
  }

  const xExtent = fc.extentDate()
    .accessors([d => d[_data.xDataAttribute]]).pad([0.01, 0]).bind(this);

  const bisectDate = d3.bisector(function (d) { return d[_data.xDataAttribute]; }).left

  // create the scales for all series of data,
  // by combine all data across full series
  var x = d3.scaleLinear()
    .domain(xExtent(fullSeriesData[0]));
  var y = d3.scaleLinear()
    .domain(yExtent(null, null, fullSeriesData[0]));

  // create the data that is bound to the charts. It is a combination of the
  // chart data and the brushed / navigator range
  const chartData = {
    series: fullSeriesData,
    // set an initial brushed range
    brushedRange: [0, 1]
  };

  // We need to see how we can define our custom color
  const color = d3.scaleOrdinal(d3.schemeCategory10);

  let updateChartTimer = null;
  const brush = fc.brushX()
    .on('brush', (evt) => {
      lastBrushSelectionEvent = evt;
      // if the brush has zero height there is no selection
      if (evt.selection) {
        if (updateChartTimer) {
          clearTimeout(updateChartTimer);
          updateChartTimer = null;
        }
        updateChartTimer = setTimeout(() => {
          updateChart(evt);
        }, 500);
      }
    });

  function updateChart(evt) {
    chartData.brushedRange = evt.selection;
    chartData.series = fullSeriesData;

    // update the domain of the main chart to reflect the brush
    mainChart.xDomain(evt.xDomain);
    console.log("rtyxwsd", yExtent(evt.xDomain[0], evt.xDomain[1], fullSeriesData[0]));
    let yDomainRe = yExtent(evt.xDomain[0], evt.xDomain[1], fullSeriesData[0]);
    mainChart.yDomain([0, yDomainRe[1]]);

    getAllSyncChart().forEach((chart) => {
      if (chart) {
        chart.xDomain(evt.xDomain);

        chart.__chartData.brushedRange = evt.selection;
        chart.__renderChart(chart.__chartData);
      }
    });
    render(chartData);
  }

  function setInitialDomain() {
    // set the initial domain for the main chart based on the
    // brushed range
    var scale = d3.scaleTime().domain(x.domain());
    mainChart.xDomain(chartData.brushedRange.map(scale.invert));
  }

  function getLineDrawer() {
    const _lineDrawer = [];

    fullSeriesData.forEach(function (value, index) {
      var line = fc.seriesSvgBar()
        .crossValue(d => d[_data.xDataAttribute])
        .mainValue(d => {
          window.currentBarColor = d[_data.yDataAttribute].color;
          return d[_data.yDataAttribute].nullify ? null : d[_data.yDataAttribute]['value']
        })
        .decorate(function (sel) {
          makeGridLines();
          insertToolTip(mainChart);
          sel.attr('stroke', (d) => {
            return d[_data.yDataAttribute].color;
          });
          
          sel.select('path').attr('class', (d) => {
            return 'fill'+d[_data.yDataAttribute].color.replace("#", '_');
          });

          // sel.select('path').style('fill', (d) => {
          //   return d[_data.yDataAttribute].color;
          // });
        });

      var drawLine = fc.seriesSvgMulti()
        .series([line])
        .mapping((data, index, series) => {
          return value;
        });

      _lineDrawer.push(drawLine);
    });

    return _lineDrawer;
  }

  var lineDrawer = getLineDrawer();

  var allSeries = [...lineDrawer];
  const allSeriesNavigator = [...lineDrawer];

  // create a multi series, combining the brush and area
  var multiLine = fc.seriesSvgMulti()
    .series(allSeries);

  // create a multi series, combining the brush and area for navigator chart
  const multiLineNavigator = fc.seriesSvgMulti()
    .series([multiLine, brush])
    .mapping((data, index, series) => {
      switch (series[index]) {
        case brush: {
          return data.brushedRange;
        }
        case multiLine: {
          return data.series;
        }
      }
    });

  function createButton(buttonName, buttonText, passValue, xposition, rectSize) {

    if (document.getElementById(chartID.replace("#", '') + buttonName)) {
      document.getElementById(chartID.replace("#", '') + buttonName).remove();
    }

    let svg = d3.select(chartID).select('svg');
    let button = svg.append('svg').attr("id", chartID.replace("#", '') + buttonName).attr("x", xposition).append("g").attr("class", chartID.replace("#", '') + buttonName).attr("x", xposition);
    let rect = button.append("rect")
      // .attr("style", "fill:rgb(80,80,83); border-width:1;")
      .attr("class", "PlotButtonCss")
      .attr("width", rectSize)
      .attr("height", 20);

    let text = button.append("text").text(function () { return buttonText; }).attr("y", 15).attr("x", 2);
    document.querySelector("." + chartID.replace("#", '') + buttonName).addEventListener("click", () => clickEvent(passValue))
  }

  function insertClickableButtonInSVG() {
    setTimeout(function () {
      createButton("day", "10Hz", 1, 20, 30);
      createButton("week", "100Hz", 2, 60, 40);
      createButton("month", "500Hz", 3, 110, 46);
      createButton("month-One", "1000Hz", 4, 166, 46);
      createButton("month-Two", "2000Hz", 5, 222, 46);
      createButton("all", "All", 6, 278, 25);

    }, 0);
  }

  function clickEvent(passValue) {

    if (passValue < 4) {
      generateSamplerData(1);
      mainChart.__chartData.series = fullSeriesData;
    }

    if (!lastBrushSelectionEvent) {
      const date1 = new Date(mainChart.xDomain()[0]);
      const date2 = new Date(mainChart.xDomain()[1]);

      lastBrushSelectionEvent = {};
      lastBrushSelectionEvent.xDomain = [date1, date2];
      lastBrushSelectionEvent.selection = [0, 1];
    }

    var newDomain;
    var perctangeOfBrushNeedToSelected = null;
    var recordForNumberOfSecond = ((lastBrushSelectionEvent.xDomain[1] - lastBrushSelectionEvent.xDomain[0]));
    var selectedBrushRange = lastBrushSelectionEvent.selection[1] - lastBrushSelectionEvent.selection[0];
    var placeForOneUnitTime = (selectedBrushRange / recordForNumberOfSecond);


    switch (passValue) {
      case 1: {
        perctangeOfBrushNeedToSelected = (placeForOneUnitTime * 10);
        if (lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected < 0) {
          mainChart.__chartData.brushedRange = [
            0,
            perctangeOfBrushNeedToSelected];
          newDomain = [0, 50];
        } else {
          mainChart.__chartData.brushedRange = [
            lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected,
            lastBrushSelectionEvent.selection[1]];
          newDomain = [x.domain()[1] - 50, x.domain()[1]];
        }
        mainChart.xDomain(newDomain);
        mainChart.yDomain([0, yExtent(newDomain[0], newDomain[1], fullSeriesData[0])[1]]);
        break;
      }

      case 2: {
        perctangeOfBrushNeedToSelected = (placeForOneUnitTime * 100);
        if (lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected < 0) {
          mainChart.__chartData.brushedRange = [
            0,
            perctangeOfBrushNeedToSelected];
          newDomain = [0, 100];
        } else {
          mainChart.__chartData.brushedRange = [
            lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected,
            lastBrushSelectionEvent.selection[1]];
          newDomain = [x.domain()[1] - 100, x.domain()[1]];
        }
        mainChart.xDomain(newDomain);
        mainChart.yDomain([0, yExtent(newDomain[0], newDomain[1], fullSeriesData[0])[1]]);
        break;
      }

      case 3: {
        perctangeOfBrushNeedToSelected = (placeForOneUnitTime * 500);
        if (lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected < 0) {
          mainChart.__chartData.brushedRange = [
            0,
            perctangeOfBrushNeedToSelected];
          newDomain = [0, 500];
        } else {
          mainChart.__chartData.brushedRange = [
            lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected,
            lastBrushSelectionEvent.selection[1]];
          newDomain = [x.domain()[1] - 500, x.domain()[1]];
        }
        mainChart.xDomain(newDomain);
        mainChart.yDomain([0, yExtent(newDomain[0], newDomain[1], fullSeriesData[0])[1]]);
        break;
      }
      case 4: {
        perctangeOfBrushNeedToSelected = (placeForOneUnitTime * 1000);
        if (lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected < 0) {
          mainChart.__chartData.brushedRange = [
            0,
            perctangeOfBrushNeedToSelected];
          newDomain = [0, 1000];
        } else {
          mainChart.__chartData.brushedRange = [
            lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected,
            lastBrushSelectionEvent.selection[1]];
          newDomain = [x.domain()[1] - 1000, x.domain()[1]];
        }
        mainChart.xDomain(newDomain);
        mainChart.yDomain([0, yExtent(newDomain[0], newDomain[1], fullSeriesData[0])[1]]);
        break;
      }

      case 5: {
        perctangeOfBrushNeedToSelected = (placeForOneUnitTime * 2000);
        if (lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected < 0) {
          mainChart.__chartData.brushedRange = [
            0,
            perctangeOfBrushNeedToSelected];
          newDomain = [0, 2000];
        } else {
          mainChart.__chartData.brushedRange = [
            lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected,
            lastBrushSelectionEvent.selection[1]];
          newDomain = [x.domain()[1] - 2000, x.domain()[1]];
        }
        mainChart.xDomain(newDomain);
        mainChart.yDomain([0, yExtent(newDomain[0], newDomain[1], fullSeriesData[0])[1]]);
        break;
      }

      case 6: {
        generateSamplerData(defaultSamplingSize);
        mainChart.__chartData.series = fullSeriesData;
        mainChart.__chartData.brushedRange = [0, 1];
        mainChart.xDomain(xExtent(fullSeriesData[0]));
        mainChart.yDomain(yExtent(null, null, fullSeriesData[0]));
        break;
      }
    }

    mainChart.__renderChart(mainChart.__chartData);
    getAllSyncChart().forEach((chart) => {
      if (chart) {
        if (passValue < 4) {
          let fullSeriesDataClone = chart.__generateSamplerData(1);
          chart.__chartData.series = fullSeriesDataClone;
          chart.__chartData.brushedRange = [
            lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected,
            lastBrushSelectionEvent.selection[1]];
          chart.xDomain(newDomain);
        } else {
          let fullSeriesDataClone = chart.__generateSamplerData(defaultSamplingSize);
          chart.__chartData.series = fullSeriesDataClone;
          chart.__chartData.brushedRange = [0, 1];
          chart.xDomain(xExtent(fullSeriesDataClone[0]));
        }
        chart.__renderChart(chart.__chartData);
      }
    });
  }

  function makeGridLines() {
    setTimeout(function () {
      /*added to show the gridlines on the chart*/
      d3.select(chartID).select('svg').append("g")
        .call(removeOld_x_gridlines())
        .attr("class", "grid")
        .attr("transform", "translate(0," + height + ")")
        // .style("stroke-dasharray", ("2,6"))
        .call(make_x_gridlines()
          .tickSize(-height)
        )
        .call(g => g.select(".domain").remove());
    }, 0);
  }
  function insertToolTip(mainChartInstanceInToolTip) {
    setTimeout(function () {
      let svg = d3.select(chartID).select('svg');
      svg.selectAll(".focus").remove();
      let focus = svg.append("g")
        .attr("class", "focus focusWhite")
        .classed("DisplayNone", true);
        // .style("display", "none");

      focus.append("circle")
        .attr("r", 5);

      focus.append("rect")
        .attr("class", "tooltipCustom")
        .attr("width", 130)
        .attr("height", 50)
        .attr("x", 10)
        .attr("y", -12)
        .attr("rx", 0)
        .attr("ry", 0);

      focus.append("text")
        .attr("class", "tooltip-frequency")
        .attr("x", 21)
        .attr("y", 3)
        .text('Frequency');

      focus.append("text")
        .attr("class", "tooltip-frequency-equal")
        .attr("x", 21)
        .attr("y", 3)
        .text('=');

      focus.append("text")
        .attr("class", "tooltip-frequency-value")
        .attr("x", 21)
        .attr("y", 3)
        .text('Frequency Value');

      focus.append("text")
        .attr("class", "tooltip-aptitude")
        .attr("x", 21)
        .attr("y", 17)
        .text('Amplitude');

      focus.append("text")
        .attr("class", "tooltip-aptitude-equal")
        .attr("x", 21)
        .attr("y", 17)
        .text('=');

      focus.append("text")
        .attr("class", "tooltip-aptitude-value")
        .attr("x", 21)
        .attr("y", 17)
        .text('Amplitude Value');

      focus.append("text")
        .attr("class", "tooltip-lf")
        .attr("x", 21)
        .attr("y", 32)
        .text('LF Multiple');

      focus.append("text")
        .attr("class", "tooltip-lf-equal")
        .attr("x", 21)
        .attr("y", 32)
        .text('=');

      focus.append("text")
        .attr("class", "tooltip-lf-value")
        .attr("x", 21)
        .attr("y", 32)
        .text('LF Value');


      setTimeout(() => {
        try {
          const svgAttribute = svg.node().getBBox();
        const width = svg._groups[0][0].width.animVal.value;
        const height = svg._groups[0][0].height.animVal.value

        if (document.getElementById(chartID.replace("#", '') + "Overlay")) {
          document.getElementById(chartID.replace("#", '') + "Overlay").remove();
        }

        svg.append("rect")
          .attr("id", chartID.replace("#", '') + "Overlay")
          .attr("class", "overlay")
          .attr("width", width)
          .attr("height", height - 21)
          .attr("y", 20)
          .on("mouseover", function () {
            focus.classed("DisplayNull", true);
            // focus.style("display", null);
          })
          .on("mouseout", function () {
            focus.classed("DisplayBlock",false);
            focus.classed("DisplayNone",true);
            //focus.style("display", "none");

            getAllSyncChart().forEach((chart) => {
              if (chart) {
                try {
                  chart.__focus.classed("DisplayBlock",true);
                  chart.__focus.classed("DisplayNone",true);
                  chart.__focusOne.classed("DisplayNone",true);
                  chart.__focusTwo.classed("DisplayNone", true);
                  // chart.__focus.style("display", "none");
                  // chart.__focusOne.style("display", "none");
                  // chart.__focusTwo.style("display", "none");
                } catch (e) {
                  //
                }
              }
            });
          })
          .on("mousemove", mousemove);
        } catch (error) {
        }
      }, 0);


      mainChartInstanceInToolTip.__focus = focus;


      function mousemove() {
        let showToolTipOnLeft = false;
        const width = svg._groups[0][0].width.animVal.value;
        if (d3.mouse(this)[0] > width / 2) {
          showToolTipOnLeft = true;
        }

        showToolTip(d3.mouse(this)[0], focus, showToolTipOnLeft);
        getAllSyncChart().forEach((chart) => {
          if (chart) {
            chart.__showToolTip(d3.mouse(this)[0], chart.__focus, chart.__focusOne, chart.__focusTwo, showToolTipOnLeft);
          }
        });
      }
    }, 0);
  }

  function showToolTip(xPoint, focus, showToolTipOnLeft) {
    try {
      focus.classed("DisplayBlock",true);
      // focus.style("display", "block");
    } catch (e) {
      //
    }

    let x0 = x.invert(xPoint),
      i = bisectDate(fullSeriesData[0], x0, 1),
      d0 = fullSeriesData[0][i - 1],
      d1 = fullSeriesData[0][i],
      d = x0 - d0.date > d1.date - x0 ? d1 : d0;

    let i1 = bisectDate(fullSeriesData[1], x0, 1),
      d01 = fullSeriesData[1][i1 - 1],
      d11 = fullSeriesData[1][i1],
      dF1 = x0 - d01.date > d11.date - x0 ? d11 : d01;

    let i2 = bisectDate(fullSeriesData[2], x0, 1),
      d02 = fullSeriesData[2][i2 - 1],
      d12 = fullSeriesData[2][i2],
      dF2 = x0 - d02.date > d12.date - x0 ? d12 : d02;

    try {
      let domainInMax = y.domain();
      focus.attr("transform", "translate(" + (x(d[_data.xDataAttribute]) - 0) + "," + (y(d[_data.yDataAttribute]['value']) - 10) + ")");
      focus.select(".tooltip-date").text(new Date(d[_data.xDataAttribute]).toDateString());
      focus.select(".tooltip-frequency-value").text(d[_data.xDataAttribute].toFixed(2));
      focus.select(".tooltip-aptitude-value").text(d[_data.yDataAttribute]['value'].toFixed(4));
      focus.select(".tooltip-lf-value").text(d[_data.yDataAttribute]['harmonicLFMult'].toFixed(2));
      let totalRange = domainInMax[1] - domainInMax[0];
      let currentValue = d[_data.yDataAttribute]['value'].toFixed(2);
      let valuePercent = (currentValue / totalRange) * 100;

      if (showToolTipOnLeft) {
        focus.select(".tooltipCustom").attr('x', 55 - 195);
        focus.select(".tooltipCustom").attr('y', -10);
        focus.select(".tooltip-frequency").attr("x", 59 - 195);
        focus.select(".tooltip-frequency-equal").attr("x", 119 - 195);
        focus.select(".tooltip-frequency-value").attr("x", 135 - 195);
        focus.select(".tooltip-aptitude").attr("x", 59 - 195);
        focus.select(".tooltip-aptitude-equal").attr("x", 119 - 195);
        focus.select(".tooltip-aptitude-value").attr("x", 135 - 195);
        focus.select(".tooltip-lf").attr("x", 59 - 195);
        focus.select(".tooltip-lf-equal").attr("x", 119 - 195);
        focus.select(".tooltip-lf-value").attr("x", 135 - 195);
        if (valuePercent < 30) {
          focus.select(".tooltipCustom").attr('y', -10 - 50);
          focus.select(".tooltip-frequency").attr("y", 3 - 50);
          focus.select(".tooltip-frequency-equal").attr("y", 3 - 50);
          focus.select(".tooltip-frequency-value").attr("y", 3 - 50);
          focus.select(".tooltip-aptitude").attr("y", 17 - 50);
          focus.select(".tooltip-aptitude-equal").attr("y", 17 - 50);
          focus.select(".tooltip-aptitude-value").attr("y", 17 - 50);
          focus.select(".tooltip-lf").attr("y", 32 - 50);
          focus.select(".tooltip-lf-equal").attr("y", 32 - 50);
          focus.select(".tooltip-lf-value").attr("y", 32 - 50);
        } else if (valuePercent > 70) {
          focus.select(".tooltipCustom").attr('y', -10 + 50);
          focus.select(".tooltip-frequency").attr("y", 3 + 50);
          focus.select(".tooltip-frequency-equal").attr("y", 3 + 50);
          focus.select(".tooltip-frequency-value").attr("y", 3 + 50);
          focus.select(".tooltip-aptitude").attr("y", 17 + 50);
          focus.select(".tooltip-aptitude-equal").attr("y", 17 + 50);
          focus.select(".tooltip-aptitude-value").attr("y", 17 + 50);
          focus.select(".tooltip-lf").attr("y", 32 + 50);
          focus.select(".tooltip-lf-equal").attr("y", 32 + 50);
          focus.select(".tooltip-lf-value").attr("y", 32 + 50);
        } else {
          focus.select(".tooltip-frequency").attr("y", 3);
          focus.select(".tooltip-frequency-equal").attr("y", 3);
          focus.select(".tooltip-frequency-value").attr("y", 3);
          focus.select(".tooltip-aptitude").attr("y", 17);
          focus.select(".tooltip-aptitude-equal").attr("y", 17);
          focus.select(".tooltip-aptitude-value").attr("y", 17);
          focus.select(".tooltip-lf").attr("y", 32);
          focus.select(".tooltip-lf-equal").attr("y", 32);

          focus.select(".tooltip-lf-value").attr("y", 32);
        }
      } else {
        focus.select(".tooltipCustom").attr('x', 10);
        focus.select(".tooltipCustom").attr('y', -10);
        focus.select(".tooltip-frequency").attr("x", 14);
        focus.select(".tooltip-frequency-equal").attr("x", 74);
        focus.select(".tooltip-frequency-value").attr("x", 90);
        focus.select(".tooltip-aptitude").attr("x", 14);
        focus.select(".tooltip-aptitude-equal").attr("x", 74);
        focus.select(".tooltip-aptitude-value").attr("x", 90);
        focus.select(".tooltip-lf").attr("x", 14);
        focus.select(".tooltip-lf-equal").attr("x", 74);
        focus.select(".tooltip-lf-value").attr("x", 90);
        if (valuePercent < 30) {
          focus.select(".tooltipCustom").attr('y', -10 - 50);
          focus.select(".tooltip-frequency").attr("y", 3 - 50);
          focus.select(".tooltip-frequency-equal").attr("y", 3 - 50);
          focus.select(".tooltip-frequency-value").attr("y", 3 - 50);
          focus.select(".tooltip-aptitude").attr("y", 17 - 50);
          focus.select(".tooltip-aptitude-equal").attr("y", 17 - 50);
          focus.select(".tooltip-aptitude-value").attr("y", 17 - 50);
          focus.select(".tooltip-lf").attr("y", 32 - 50);
          focus.select(".tooltip-lf-equal").attr("y", 32 - 50);
          focus.select(".tooltip-lf-value").attr("y", 32 - 50);
        } else if (valuePercent > 70) {
          focus.select(".tooltipCustom").attr('y', -10 + 50);
          focus.select(".tooltip-frequency").attr("y", 3 + 50);
          focus.select(".tooltip-frequency-equal").attr("y", 3 + 50);
          focus.select(".tooltip-frequency-value").attr("y", 3 + 50);
          focus.select(".tooltip-aptitude").attr("y", 17 + 50);
          focus.select(".tooltip-aptitude-equal").attr("y", 17 + 50);
          focus.select(".tooltip-aptitude-value").attr("y", 17 + 50);
          focus.select(".tooltip-lf").attr("y", 32 + 50);
          focus.select(".tooltip-lf-equal").attr("y", 32 + 50);
          focus.select(".tooltip-lf-value").attr("y", 32 + 50);
        } else {
          focus.select(".tooltip-frequency").attr("y", 3);
          focus.select(".tooltip-frequency-equal").attr("y", 3);
          focus.select(".tooltip-frequency-value").attr("y", 3);
          focus.select(".tooltip-aptitude").attr("y", 17);
          focus.select(".tooltip-aptitude-equal").attr("y", 17);
          focus.select(".tooltip-aptitude-value").attr("y", 17);
          focus.select(".tooltip-lf").attr("y", 32);
          focus.select(".tooltip-lf-equal").attr("y", 32);
          focus.select(".tooltip-lf-value").attr("y", 32);
        }
      }
    } catch (e) {
      //
    }
  }

  var mainChart = fc.chartSvgCartesian(x, y)
    .yLabel("% of LF Magnitude")
    .xLabel("Frequency (Hz)")
    .yTickFormat(d3.format("~s"))
    .xTickFormat(d3.format(".0f"))
    .yTickArguments([4])
    .yOrient("left")
    .plotArea(multiLine);

  const navigatorChart = fc.chartSvgCartesian(x.copy(), y.copy())
    .xTickFormat(d3.format(".0f"))
    .plotArea(multiLineNavigator);

  setInitialDomain();

  const render = (_chartData) => {

    //Redefining the chart range on sampling change.
    lineDrawer = getLineDrawer();
    allSeries = [...lineDrawer];
    multiLine = fc.seriesSvgMulti()
      .series(allSeries);

    mainChart = fc.chartSvgCartesian(x, y)
      .yLabel("% of LF Magnitude")
      .xLabel("Frequency (Hz)")
      .yTickFormat(d3.format("~s"))
      .xTickFormat(d3.format(".0f"))
      .yTickArguments([4])
      .yOrient("left")
      .plotArea(multiLine);

    mainChart.__chartData = _chartData;
    mainChart.__renderChart = render;
    mainChart.__getChartInstance = getChartInstance;
    mainChart.__generateSamplerData = generateSamplerData;

    d3.select(chartID)
      .datum(_chartData.series)
      .call(mainChart);

    d3.select(navigatorChartID)
      .datum(chartData)
      .call(navigatorChart);

    makeGridLines();
    
    insertToolTip(mainChart);
    mainChart.__showToolTip = showToolTip;

    insertClickableButtonInSVG();

    globalChartInstance = mainChart;

    return mainChart;
  }
  render(chartData);

  function getChartInstance() {
    return globalChartInstance;
  }

  mainChart.__showToolTip = showToolTip;
  mainChart.__getChartInstance = getChartInstance;
  mainChart.__chartData = chartData;
  mainChart.__renderChart = render;
  mainChart.__generateSamplerData = generateSamplerData;

  return mainChart;
}

window.BuildBarChart = BuildBarChart;
