import equApi from '../api/equipement';
import transformData from '../api/utility';
import {push} from 'connected-react-router';

export function addInterval(data){
    return function (dispatch) {
        return dispatch({ type:'INTERVAL', data });
    }
}

export function clearInterval(){
    return function (dispatch) {
        return dispatch({ type:'CLEAR_INTERVAL', data:null });
    }
}

export function viewPowerMetering(metering, serialNumber) {
    metering = transformData.transformNumericValue(metering);
    let name = metering.name;
    let payload = {metering, serialNumber, name};
    return function (dispatch) {
        dispatch ({type: 'VIEW_POWER_METERING', payload});
        dispatch (push('/power-metering') )
    };
}

export function viewPowerMeteringAdmin(metering, serialNumber){
    console.log("viewPowerMeteringAdmingoing")
    metering = transformData.transformNumericValue(metering);
    let name = metering.name;
    let payload = {metering, serialNumber, name};
    return function (dispatch) {
        dispatch({type: 'VIEW_POWER_METERING_ADMIN',payload});
        dispatch (push('/power-metering') )
    };
}

// export function viewPowerMeteringAdmin(metering, serialNumber) {
//     metering = transformData.transformNumericValue(metering);
//     let name = metering.name;
//     let payload = {metering, serialNumber, name};
//     return {type: 'VIEW_POWER_METERING_ADMIN', payload};
// }

export function setIntervalOne(data) {
    return {type: 'SETINTERVAL', data};
}

export function setIntervalTwo(data) {
    return {type: 'SETINTERVAL2', data};
}


export function clearState() {
    return {type: 'CLEAR'};
}

export function makeChartLoaded() {
    return {type: 'MAKE_CHART_LOADED'};
}

export function refPowerMetering(metering) {
    metering = transformData.transformNumericValue(metering);
    return {type: 'REF_POWER_METERING', metering};
}

export function moveSubNodePage(node){
    return function (dispatch) {
        dispatch({type: 'MOVE_SUBNODE_PAGE',node});
        setTimeout( () => dispatch (push('/subNode-power-metering') ), 500 )
    };
}

export function refsSubNodeMeter(metering,serialNumber){
    metering = transformData.transformNumericValue(metering);
    return {type: 'REFS_SUBNODE_METER', metering,serialNumber};
}

export function loadPowerMetering(serialNumber, changePage) {
    return function (dispatch, getState) {

        setTimeout(
            function () {
                let refCall = false;
                let token = getState().application.token;
                if (serialNumber === undefined) {
                    refCall = true;
                    serialNumber = getState().application.selectedSerialNumber;
                    if(serialNumber === null || serialNumber === undefined || serialNumber.length === 0)
                    {
                        dispatch({type: 'LOAD_EQUIPMENT_PORTFOLIO'});
                    }
                }
                return equApi.getPowerMetering(token, serialNumber).then(data => {
                    if (refCall === false || changePage === true) {
                        dispatch(viewPowerMetering(data, serialNumber));
                    } else {
                        dispatch(refPowerMetering(data));
                    }
                }).catch(error => {
                    if (error === undefined || error.httpStatusCode === 405) {
                        dispatch({type: 'LOAD_LOGIN'});
                    }else {
                        throw error;
                    }
                });
            }, 0);
    };
}

export function loadSubNodeMeterPage (serialNumber) {
    return function (dispatch,getState){
        setTimeout(
            function () {
                let token = getState().application.token;
                console.log("loadSubNodeMeterPage",getState(),token)
                return equApi.getPowerMetering(token, serialNumber).then(data => {
                    dispatch(refsSubNodeMeter(data,serialNumber));
                }).catch(error => {
                    if (error === undefined || error.httpStatusCode === 405 || error.httpStatusCode === 401) {
                        dispatch({type: 'LOAD_LOGIN'});
                    }else {
                        throw error;
                    }
                });
            }, 0);
    };
}

export function loadPowerMeteringAdmin(serialNumber, changePage) {
    console.log("serialNumber, changePage",serialNumber, changePage);
    return function (dispatch, getState) {
        setTimeout(
            function () {
                let refCall = false;
                let token = getState().application.token;

                if (serialNumber === undefined) {
                    console.log("serialNumber",serialNumber);
                    refCall = true;
                    serialNumber = getState().application.selectedSerialNumber;
                    console.log("serialNumber From Session",serialNumber);
                    if(serialNumber === null || serialNumber === undefined || serialNumber.length === 0)
                    {
                        console.log("ifserialNumber",serialNumber);
                        dispatch({type: 'LOAD_EQUIPMENT_PORTFOLIO'});
                    }
                }
                return equApi.getPowerMetering(token, serialNumber).then(data => {
                    if (refCall === false || changePage === true) {
                        console.log("refCall === false || changePage === true",serialNumber)
                        dispatch(viewPowerMeteringAdmin(data, serialNumber));
                    } else {
                        console.log("else",data);
                        dispatch(refPowerMetering(data));
                    }
                }).catch(error => {
                    if (error === undefined || error.httpStatusCode === 405) {
                        dispatch({type: 'LOAD_LOGIN'});
                    }else {
                        throw error;
                    }
                });
            }, 0);
    };
}


export function RedirectToEquipmentPortfolio(){

    return function (dispatch) {
        dispatch({type: 'LOAD_EQUIPMENT_PORTFOLIO'});
    };
}


/* Get All Node Accs to FacilityId start*/
export function loadNodeAccToFacility(token, data) {
    console.log("loadNodeAccToFacility action")
    return function (dispatch, getState) {
        setTimeout(function () {
            token = getState().application.token;
            data = getState().application.facilityId;
            equApi.getNodeAccToFacilityForPowerMetering(token,data).then(data => {
                return dispatch(getNodeListforPowerMeterPage(data));
            }).catch(error => {
                return dispatch(onError(error));
            });
        }, 0);
    };
}

export function getNodeListforPowerMeterPage(data) {
    return {type: "GET_NODE_LIST_FOR_POWER_METER_PAGE", data};
}
export function onError(data) {
    return {type: "ON_ERROR", data};
}