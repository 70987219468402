import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import * as viewPowerMeteringAction from '../../actions/viewPowerMetering';
import * as viewEquipmentPortfolio from '../../actions/viewEquipmentPortfolio';
import * as viewMoterAnalysis from '../../actions/viewMotorAnalysis';
import * as viewWaveForm from '../../actions/waveForm';
import * as viewTrendForm from '../../actions/trendAnalysis';
import * as redirectToEquipment from '../../actions/logOffAction';
import $ from "jquery";

class TopNavigation extends React.Component {

    constructor(props) {
        super(props);
        this.power = 'power';
    }

    componentWillMount(){
        if(this.props.token === ""){
            this.props.redirect.redirectToEquipmentPage()
        }
    }

    isActive = (activeNode) => {
        if (activeNode === this.props.activeNode) {
            return 'act';
        }
        return '';
    };

    onLoadMotorAnalysis = () => {
        try {
            window.requestGetTrendDataAll.abort();
        } catch (e) {
            console.log("Already aborted");
        }

        $('.loaderPosition').removeClass('hidden');
        console.log('XYZ', this.props.selectedSerialNumber);
        //this.props.actionViewPowerMetering.clearState();
        this.props.actionViewMotorAnalysis.loadMotorAnalysis(this.props.selectedSerialNumber);
        // setTimeout(function () {
        //     this.props.actionViewPowerMetering.clearState();
        // }.bind(this),1000)
    };

    onLoadWaveForm = () => {
        try {
            window.requestGetTrendDataAll.abort();
        } catch (e) {
            console.log("Already aborted");
        }

        $('.loaderPosition').removeClass('hidden');
        //this.props.actionViewPowerMetering.clearState();
        this.props.actionWaveFrom.loadWaveFormNew();
    };

    onTrendingForm = () => {
        $('.loaderPosition').removeClass('hidden');
        //this.props.actionViewPowerMetering.clearState();
        this.props.actionTrendForm.loadTrendFormNew();
    };

    onLoadPowerMetering = () => {
        try {
            window.requestGetTrendDataAll.abort();
        } catch (e) {
            console.log("Already aborted");
        }

        $('.loaderPosition').removeClass('hidden');
        this.props.actionViewPowerMetering.loadPowerMeteringAdmin(undefined, true);
        this.props.actionTrendForm.loadTrendFormForPowerMeterPageSample(this.props.selectedNode, this.power);
        setTimeout(() => this.props.actionViewPowerMetering.clearState(), 2000);
    };

    render() {
        console.log("this.props.facilityId", this.props);
        return (
            <div className="topMenu bdRadius spmb30 clearfix">
                <ul>
                    <li className={"equipPortLink " + this.isActive(1) + " " + (this.props.hidePowerMeteringIcon === true ? "eqPortSpecialPosition" : "")}>
                        <Link to="equipment-portfolio">
                            <span/>
                        </Link>
                    </li>
                    {
                        this.props.source === "subNodePage" ?
                            <li className={"subNodeMeterLink " + this.isActive(2) + " " + (this.props.hideInfo === true ? "linkPosition" : "")}
                                onClick={this.onLoadSubNodePage}>
                                <Link to="subNode-power-metering">
                                    <span/>
                                </Link>
                            </li> :
                            this.props.hidePowerMeteringIcon === true ?
                                null :
                                <li className={"powerMeterLink " + this.isActive(2) + " " + (this.props.hideInfo === true ? "linkPosition" : "")}
                                    onClick={this.onLoadPowerMetering}>
                                    <a href="javascript:void(0);">
                                        <span/>
                                    </a>
                                </li>
                    }

                    <li className={"waveFormsLink " + this.isActive(3) + " " + (this.props.hideInfo === true ? "hideIconStatus" : "")}
                        onClick={this.onLoadWaveForm}>
                        <a href="javascript:void(0);">
                            <span/>
                        </a>
                    </li>
                    <li className={"motorAnalyLink " + this.isActive(4) + " " + (this.props.hideInfo === true ? "hideIconStatus" : "")}
                        onClick={this.onLoadMotorAnalysis}>
                        <a href="javascript:void(0);">
                            <span/>
                        </a>
                    </li>
                    <li className={"trendAnalyLink " + this.isActive(5) + " " + (this.props.hideInfo === true ? "hideIconStatus" : "")}
                        onClick={this.onTrendingForm}>
                        <a href="javascript:void(0);">
                            <span/>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state.application;
};

const mapDispatchToProps = dispatch => {
    return {
        actionViewEquipmentPortfolio: bindActionCreators(viewEquipmentPortfolio, dispatch),
        actionViewPowerMetering: bindActionCreators(viewPowerMeteringAction, dispatch),
        actionViewMotorAnalysis: bindActionCreators(viewMoterAnalysis, dispatch),
        actionWaveFrom: bindActionCreators(viewWaveForm, dispatch),
        actionTrendForm: bindActionCreators(viewTrendForm, dispatch),
        redirect: bindActionCreators(redirectToEquipment, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigation);
