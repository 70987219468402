import $ from 'jquery';
import config from './config';

class equipmentApi {


    static getEquipmentPortfolio(token, facilityIdList, listOnly) {
        return new Promise((resolve, reject) => {

            let request = $.ajax({
                url: config.baseURL + "portfolio",
                method: "GET",
                data: {
                    listOnly:listOnly,
                    facilityIdList:facilityIdList,
                },
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                dataType: "json"
            });

            request.done(function (msg) {
                resolve(msg.data);
            });

            request.fail(function (jqXHR, textStatus) {
                if (jqXHR.responseJSON !== undefined && jqXHR.responseJSON !== null) {
                    reject(jqXHR.responseJSON);
                }
                else {
                    reject("Unknown error");
                }
            });
        });
    }


    

    static getPowerMetering(token, serialNumber) {

        return new Promise((resolve, reject) => {

            let request = $.ajax({
                url: config.baseURL + "locations/" + serialNumber + "/metering?portfolio=true",
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                dataType: "json"
            });

            request.done(function (msg) {

                resolve(msg.data);
            });

            request.fail(function (jqXHR, textStatus) {
                if (jqXHR.responseJSON !== undefined && jqXHR.responseJSON !== null) {
                    reject(jqXHR.responseJSON);
                }
                else {
                    reject("Unknown error");
                }
            });
        });
    }


    static getNodeAccToFacilityForPowerMetering(token, facilityId) {
        console.log("facilityId in Api",token,facilityId)

        return new Promise((resolve, reject) => {

            let request = $.ajax({
                url: config.baseURL + "facilities/" +  facilityId+ "/portfolio",
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                dataType: "json"
            });

            request.done(function (msg) {
                resolve(msg.data);
            });

            request.fail(function (jqXHR, textStatus) {
                if (jqXHR.responseJSON !== undefined && jqXHR.responseJSON !== null) {
                    reject(jqXHR.responseJSON);
                }
                else {
                    reject("Unknown error");
                }
            });
        });
    }

    static getMotorAnalysis(token, serialNumber) {
        return new Promise((resolve, reject) => {

            let request = $.ajax({
                url: config.baseURL + "locations/" + serialNumber + "/analysis",
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                dataType: "json"
            });

            request.done(function (msg) {
                resolve(msg.data);
            });

            request.fail(function (jqXHR, textStatus) {
                resolve({});
            });
        });
    }

    static getNodeNetworkConfigs(token, locationNodeId) {
        return new Promise((resolve, reject) => {

            let request = $.ajax({
                url: config.baseURL + "locations/" + locationNodeId + "/networkconfig",
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                dataType: "json"
            });

            request.done(function (msg) {
                resolve(msg.data);
            });

            request.fail(function (jqXHR, textStatus) {
                resolve({});
            });
        });
    }

}

export default equipmentApi;