import equApi from '../api/equipement';
import config from '../api/config'
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

export function addInterval(data){
    return function (dispatch) {
        return dispatch({ type:'INTERVAL', data });
    }
}

export function clearInterval(){
    return function (dispatch) {
        return dispatch({ type:'CLEAR_INTERVAL', data:null });
    }
}

export function viewEquipmentPortfolio(equipments){
    return { type:'VIEW_EQUIPMENT_PORTFOLIO', equipments };
}

export function setSelectedDevice(selectedNode, nodeName,nodeTitle) {
    return { type:'SET_SELECTED_NODE', selectedNode, nodeName,nodeTitle };
}


export function loadEquipmentPortfolio(facilityIdList,listOnly){
    return function (dispatch, getState) {

        function getCookie(c_name) {
            if (document.cookie.length > 0) {
                let c_start = document.cookie.indexOf(c_name + "=");
                if (c_start != -1) {
                    c_start = c_start + c_name.length + 1;
                    let c_end = document.cookie.indexOf(";", c_start);
                    if (c_end == -1) {
                        c_end = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(c_start, c_end));
                }
            }
            return "";
        }
        
    

        setTimeout(
            function () {
                let token = getState().application.token;

                if(token.length < 1)
                {
                    token = CryptoAES.decrypt(getCookie('apiToken').toString(),config.encrypt_key_1+config.encrypt_key_2).toString(CryptoENC);
                }
               
                return equApi.getEquipmentPortfolio(token,facilityIdList,listOnly).then(data =>{
                    dispatch(viewEquipmentPortfolio(data));
                }).catch(error => {
                    if(error === undefined || error.httpStatusCode === 405){
                        dispatch({ type:'LOAD_LOGIN' });
                    }
                });
            },0);
    };
}


export function RedirectToEquipmentPortfolio() {

    return function (dispatch) {
        dispatch({type: 'LOAD_EQUIPMENT_PORTFOLIO'});
    };
}

export function changeIconStatus(sites){
    return { type:'CHANGE_ICON_STATUS', sites };
}

