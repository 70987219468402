import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import store, {history} from './store';
import * as serviceWorker from './serviceWorker';
import App from './components/RouteHandling/index';
import './css/custom.css'; //Webpack can import CSS files too!
import './css/bootstrap.css';
import {ConnectedRouter} from 'connected-react-router';

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App/>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
//  If you want your app to work offline and load faster, you can change
//  unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();
