import $ from 'jquery';
import config from './config';


class transformData {

    static transformNumericValue(dataObject) {

        for (let key in dataObject) {
            let value = dataObject[key];
            if (typeof value === "number") {
                dataObject[key] = value.toFixed(config.datapoint_precision);
            }
        }
        if (dataObject.voltageImbalanceAlert > 0) {
            $('.target_element1').css({"background": "#f5a523"});
        }
        if (dataObject.voltageThdAlert > 0) {
            $('.target_element2').css({"background": "#f5a523"});
        }
        if (dataObject.currentImbalanceAlert > 0) {
            $('.target_element3').css({"background": "#f5a523"});
        }
        if (dataObject.currentThdAlert > 0) {
            $('.target_element4').css({"background": "#f5a523"});
        }
        if (dataObject.powerFactorAlert > 0) {
            $('.target_element5').css({"background": "#f5a523"});
        }
        if (dataObject.rpmAlert > 0) {
            $('.target_element6').css({"background": "#f5a523"});
        }
        if (dataObject.torqueAlert > 0) {
            $('.target_element7').css({"background": "#f5a523"});
        }
        if (dataObject.vDivFAlert > 0) {
            $('.target_element8').css({"background": "#f5a523"});
        }
        if (dataObject.voltageAlert > 0) {
            $('.target_element9').css({"background": "#f5a523"});
        }
        if (dataObject.currentAlert > 0) {
            $('.target_element10').css({"background": "#f5a523"});
        }
        if (dataObject.hpAlert > 0) {
            $('.target_element11').css({"background": "#f5a523"});
        }
        if (dataObject.lineFrequencyAlert > 0) {
            $('.target_element12').css({"background": "#f5a523"});
        }
        if (dataObject.vaLCAlert > 0) {
            $('.target_element13').css({"background": "#f5a523"});
        }
        if (dataObject.vbLCAlert > 0) {
            $('.target_element14').css({"background": "#f5a523"});
        }
        if (dataObject.vcLCAlert > 0) {
            $('.target_element15').css({"background": "#f5a523"});
        }
        return dataObject;
    }
}



export default transformData;

