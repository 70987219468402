import * as d3 from "d3";
import * as fc from "d3fc"
import moment from "moment";
export default function BuildLineChartWave(_data, chartID, navigatorChartID, customChartControlData, getAllSyncChart, defaultSamplingSize, afterHowManyDaysWillShowAllDataPoint, defaultBrushRange, intervalGapInDatPoint) {
    var globalChartInstance = null;

    console.log("_data Wave Form", _data);

    var lastBrushSelectionEvent = null;

    function getSampler(samplerSize) {
        console.log("fc_11", fc);
        var sampler = fc.largestTriangleThreeBucket();

        // Configure the x / y value accessors
        sampler.x(function (d) { return d[_data.xDataAttribute]; })
            .y(function (d) { return d[_data.yDataAttribute]['value']; });

        // Size of buck need to define dynamically according
        // to number of record need to render on UI
        sampler.bucketSize(samplerSize);
        return sampler;
    }

    let fullSeriesData = [];

    function generateSamplerData(samplerSize) {
        var sampler = getSampler(samplerSize);
        console.log("sampler", _data)
        var _fullSeriesData = [];
        _data.series.forEach(function (value = []) {
            console.log("33", value);
            _fullSeriesData.push(sampler(value));
        });

        fullSeriesData = _fullSeriesData;

        console.log("FULL-Series-Data", fullSeriesData);

        return _fullSeriesData;
    }
    generateSamplerData(defaultSamplingSize);

    const yExtent = function (xLeft, xRight, fullData) {
        let linerExtender = fc.extentLinear()
            .accessors([d => {
                if (xLeft && xRight) {
                    if (d[_data.xDataAttribute] >= xLeft && d[_data.xDataAttribute] <= xRight) {
                        return d[_data.yDataAttribute]['value']
                    }
                } else {
                    return d[_data.yDataAttribute]['value']
                }
            }])
            .pad([0.4, 0.4]).bind(this);
        return linerExtender(fullData);
    }

    const xExtent = fc.extentDate()
        .accessors([d => d[_data.xDataAttribute]]).pad([0.01, 0.01]).bind(this);
    console.log("xExtent", xExtent);

    const bisectDate = d3.bisector(function (d) { return d[_data.xDataAttribute]; }).left

    // create the scales for all series of data,
    // by combine all data across full series
    var x = d3.scaleTime().range([0, 500]);;

    console.log("Full Series", fullSeriesData)

    if (customChartControlData.scaleType) {
        x = d3 && d3.scaleLinear()
            .domain(xExtent(fullSeriesData[0]));
    } else {
        x = d3 && d3.scaleTime()
            .domain(xExtent(fullSeriesData[0]));
    }

    let height = 250;
    let width = 10000;
    // add the X,Y gridlines

    // gridlines in x axis function
    function make_x_gridlines() {
        return d3.axisBottom(x)
    }

    // gridlines in y axis function
    function make_y_gridline() {
        return d3.axisLeft(y)
            .ticks(1)
    }

    //removing old gridlines when chart is updating 
    function removeOld_gridlines() {
        d3.select(chartID).selectAll('.grid').remove();
        d3.select(chartID).selectAll('.gridAtZero').remove();
        return d3.axisBottom(x)
    }



    let dataOfAllSeries = []
    if (fullSeriesData.length > 2) {
        dataOfAllSeries = [...fullSeriesData[0], ...fullSeriesData[1], ...fullSeriesData[2]];
        console.log("Line Wave >>>>>", dataOfAllSeries)
    }

    var y = d3.scaleLinear()
        .domain(yExtent(null, null, dataOfAllSeries.length > 0 ? dataOfAllSeries : fullSeriesData[0]));

    // create the data that is bound to the charts. It is a combination of the
    // chart data and the brushed / navigator range
    const chartData = {
        series: fullSeriesData,
        // set an initial brushed range
        brushedRange: [0, 1]
    };

    // We need to see how we can define our custom color
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    let updateChartTimer = null;
    const brush = fc.brushX()
        .on('brush', (evt) => {
            lastBrushSelectionEvent = evt;
            // if the brush has zero height there is no selection
            if (evt.selection) {
                if (updateChartTimer) {
                    clearTimeout(updateChartTimer);
                    updateChartTimer = null;
                }
                updateChartTimer = setTimeout(() => {
                    updateChart(evt);
                }, 500);
            }
        });

    function updateChart(evt) {
        chartData.brushedRange = evt.selection;

        //86400 is the number of seconds in one day
        var recordForNumberOfDay = ((evt.xDomain[1] - evt.xDomain[0]) / 1000) / 86400;

        if (recordForNumberOfDay <= afterHowManyDaysWillShowAllDataPoint) {
            generateSamplerData(1);
        } else {
            generateSamplerData(defaultSamplingSize);
        }
        chartData.series = fullSeriesData;

        // update the domain of the main chart to reflect the brush
        let dataOfAllSeries = []
        if (fullSeriesData.length > 2) {
            dataOfAllSeries = [...fullSeriesData[0], ...fullSeriesData[1], ...fullSeriesData[2]];
            console.log("Line Wave >>>>>", dataOfAllSeries)
        }
        mainChart.xDomain(evt.xDomain);
        console.log("evt.xDomain", evt.xDomain);
        mainChart.yDomain(yExtent(evt.xDomain[0], evt.xDomain[1], dataOfAllSeries.length > 0 ? dataOfAllSeries : fullSeriesData[0]));

        getAllSyncChart().forEach((chart) => {
            if (chart) {
                chart.xDomain(evt.xDomain);
                let dataOfAllSeriesX = []
                if (chart.__chartData.series.length > 2) {
                    dataOfAllSeriesX = [...chart.__chartData.series[0], ...chart.__chartData.series[1], ...chart.__chartData.series[2]];
                }
                chart.yDomain(chart.__yExtent(evt.xDomain[0], evt.xDomain[1], dataOfAllSeriesX.length > 0 ? dataOfAllSeriesX : chart.__chartData.series[0]));

                if (recordForNumberOfDay <= afterHowManyDaysWillShowAllDataPoint) {
                    chart.__chartData.series = chart.__generateSamplerData(1);
                } else {
                    chart.__chartData.series = chart.__generateSamplerData(defaultSamplingSize);
                }

                chart.__chartData.brushedRange = evt.selection;
                chart.__renderChart(chart.__chartData);
            }
        });
        render(chartData);
    }

    function setInitialDomain() {
        // set the initial domain for the main chart based on the
        // brushed range
        var scale = d3.scaleTime().domain(x.domain());
        mainChart.xDomain(chartData.brushedRange.map(scale.invert));
    }

    function getLineDrawer() {
        const _lineDrawer = [];

        fullSeriesData.forEach(function (value, index) {
            var line = fc.seriesSvgLine()
                .crossValue(d => d[_data.xDataAttribute])
                .mainValue(d => {
                    return d[_data.yDataAttribute].nullify ? null : d[_data.yDataAttribute]['value']
                })
                .decorate(function (sel) {
                    makeXGridLines();
                    makeYGridLines();
                    insertToolTip(mainChart);
                    sel.attr('stroke', (_, i) => value[0] && value[0].yScale.color)
                });

            var drawLine = fc.seriesSvgMulti()
                .series([line])
                .mapping((data, index, series) => {
                    return value;
                });

            _lineDrawer.push(drawLine);
        });

        return _lineDrawer;
    }

    var lineDrawer = getLineDrawer();

    var allSeries = [...lineDrawer];
    const allSeriesNavigator = [...lineDrawer];

    // create a multi series, combining the brush and area
    var multiLine = fc.seriesSvgMulti()
        .series(allSeries);

    // create a multi series, combining the brush and area for navigator chart
    const multiLineNavigator = fc.seriesSvgMulti()
        .series([multiLine, brush])
        .mapping((data, index, series) => {
            switch (series[index]) {
                case brush: {
                    return data.brushedRange;
                }
                case multiLine: {
                    return data.series;
                }
            }
        });

    function createButton(buttonName, buttonText, passValue, xposition, rectSize) {

        if (document.getElementById(chartID.replace("#", '') + buttonName)) {
            document.getElementById(chartID.replace("#", '') + buttonName).remove();
        }

        let svg = d3.select(chartID).select('svg');
        let button = svg.append('svg').attr("id", chartID.replace("#", '') + buttonName).attr("x", xposition).append("g").attr("class", chartID.replace("#", '') + buttonName).attr("x", xposition);
        let rect = button.append("rect")
            // .attr("style", "fill:rgb(80,80,83); border-width:1;")
            .attr("class", "PlotButtonCss")
            .attr("width", rectSize)
            .attr("height", 20);

        let text = button.append("text").text(function () { return buttonText; }).attr("y", 15).attr("x", 2);
        document.querySelector("." + chartID.replace("#", '') + buttonName).addEventListener("click", () => clickEvent(passValue))
    }

    function insertClickableButtonInSVG() {
        setTimeout(function () {
            if (customChartControlData.rangeButtons && customChartControlData.rangeButtons.length > 0) {
                customChartControlData.rangeButtons.forEach((rangeData) => {
                    createButton(rangeData.buttonName, rangeData.buttonText, rangeData.passValue, rangeData.xposition, rangeData.rectSize);
                })
            }

        }, 0);
    }

    function clickEvent(passValue) {

        if (passValue < 4) {
            generateSamplerData(1);
            mainChart.__chartData.series = fullSeriesData;
        }

        if (!lastBrushSelectionEvent) {
            const date1 = new Date(mainChart.xDomain()[0]);
            const date2 = new Date(mainChart.xDomain()[1]);

            lastBrushSelectionEvent = {};
            lastBrushSelectionEvent.xDomain = [date1, date2];
            lastBrushSelectionEvent.selection = [0, 1];
        }

        var newDomain;
        var perctangeOfBrushNeedToSelected = null;
        var recordForNumberOfSecond = ((lastBrushSelectionEvent.xDomain[1] - lastBrushSelectionEvent.xDomain[0]));
        var selectedBrushRange = lastBrushSelectionEvent.selection[1] - lastBrushSelectionEvent.selection[0];
        var placeForOneUnitTime = (selectedBrushRange / recordForNumberOfSecond);

        switch (passValue) {
            case 1: {
                let dataOfAllSeriesX1 = []
                if (mainChart.__chartData.series.length > 2) {
                    dataOfAllSeriesX1 = [...mainChart.__chartData.series[0], ...mainChart.__chartData.series[1], ...mainChart.__chartData.series[2]];
                }
                perctangeOfBrushNeedToSelected = (placeForOneUnitTime * 5);
                if (lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected < 0) {
                    mainChart.__chartData.brushedRange = [
                        0,
                        perctangeOfBrushNeedToSelected];
                    newDomain = [0, 5];
                } else {
                    mainChart.__chartData.brushedRange = [
                        lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected,
                        lastBrushSelectionEvent.selection[1]];
                    newDomain = [x.domain()[1] - 5, x.domain()[1]];
                }
                mainChart.xDomain(newDomain);
                mainChart.yDomain(yExtent(newDomain[0], newDomain[1], dataOfAllSeriesX1.length > 0 ? dataOfAllSeriesX1 : fullSeriesData[0]));
                break;
            }

            case 2: {
                let dataOfAllSeriesX2 = []
                if (mainChart.__chartData.series.length > 2) {
                    dataOfAllSeriesX2 = [...mainChart.__chartData.series[0], ...mainChart.__chartData.series[1], ...mainChart.__chartData.series[2]];
                }
                perctangeOfBrushNeedToSelected = (placeForOneUnitTime * 10);
                if (lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected < 0) {
                    mainChart.__chartData.brushedRange = [
                        0,
                        perctangeOfBrushNeedToSelected];
                    newDomain = [0, 10];
                } else {
                    mainChart.__chartData.brushedRange = [
                        lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected,
                        lastBrushSelectionEvent.selection[1]];
                    newDomain = [x.domain()[1] - 10, x.domain()[1]];
                }
                mainChart.xDomain(newDomain);
                mainChart.yDomain(yExtent(newDomain[0], newDomain[1], dataOfAllSeriesX2.length > 0 ? dataOfAllSeriesX2 : fullSeriesData[0]));
                break;
            }

            case 3: {
                let dataOfAllSeriesX3 = []
                if (mainChart.__chartData.series.length > 2) {
                    dataOfAllSeriesX3 = [...mainChart.__chartData.series[0], ...mainChart.__chartData.series[1], ...mainChart.__chartData.series[2]];
                }
                perctangeOfBrushNeedToSelected = (placeForOneUnitTime * 25);
                if (lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected < 0) {
                    mainChart.__chartData.brushedRange = [
                        0,
                        perctangeOfBrushNeedToSelected];
                    newDomain = [0, 25];
                } else {
                    mainChart.__chartData.brushedRange = [
                        lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected,
                        lastBrushSelectionEvent.selection[1]];
                    newDomain = [x.domain()[1] - 25, x.domain()[1]];
                }
                mainChart.xDomain(newDomain);
                mainChart.yDomain(yExtent(newDomain[0], newDomain[1], dataOfAllSeriesX3.length > 0 ? dataOfAllSeriesX3 : fullSeriesData[0]));
                break;
            }

            case 4: {
                generateSamplerData(defaultSamplingSize);
                mainChart.__chartData.series = fullSeriesData;
                mainChart.__chartData.brushedRange = [0, 1];
                mainChart.xDomain(xExtent(fullSeriesData[0]));
                break;
            }
        }

        mainChart.__renderChart(mainChart.__chartData);
        getAllSyncChart().forEach((chart) => {
            if (chart) {
                if (passValue < 4) {
                    let fullSeriesDataClone = chart.__generateSamplerData(1);

                    let dataOfAllSeriesX4 = [];
                    if (chart.__chartData.series.length > 2) {
                        dataOfAllSeriesX4 = [...chart.__chartData.series[0], ...chart.__chartData.series[1], ...chart.__chartData.series[2]];
                    }
                    chart.__chartData.series = fullSeriesDataClone;
                    if (lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected < 0) {
                        chart.__chartData.brushedRange = [
                            0,
                            perctangeOfBrushNeedToSelected];
                    } else {
                        chart.__chartData.brushedRange = [
                            lastBrushSelectionEvent.selection[1] - perctangeOfBrushNeedToSelected,
                            lastBrushSelectionEvent.selection[1]];
                    }
                    chart.xDomain(newDomain);
                    chart.yDomain(yExtent(newDomain[0], newDomain[1], dataOfAllSeriesX4.length > 0 ? dataOfAllSeriesX4 : fullSeriesData[0]));
                } else {
                    let fullSeriesDataClone = chart.__generateSamplerData(defaultSamplingSize);
                    chart.__chartData.series = fullSeriesDataClone;
                    chart.__chartData.brushedRange = [0, 1];
                    chart.xDomain(xExtent(fullSeriesDataClone[0]));
                }
                chart.__renderChart(chart.__chartData);
            }
        });
    }

    function makeXGridLines() {
        setTimeout(function () {
            /*added to show the gridlines on the chart*/
            d3.select(chartID).select('svg').append("g")
                .call(removeOld_gridlines())
                .attr("class", "grid")
                .attr("transform", "translate(0," + height + ")")
                // .style("stroke-dasharray", ("2,6"))
                .call(make_x_gridlines()
                    .tickSize(-height)
                )
                .call(g => g.select(".domain").remove());
        }, 0);
    }
    function makeYGridLines() {
        setTimeout(function () {
            /*added to show the gridlines on the chart*/
            d3.select(chartID).select('svg').append("g")
                .attr("class", "gridAtZero")
                .attr("transform", "translate( 0,0)")
                //.style("stroke-dasharray", ("2,4"))
                .call(make_y_gridline()
                    .tickSize(-width)
                )
                .call(g => g.select(".domain").remove());
        }, 0);
    }

    function insertToolTip(mainChartInstanceInToolTip) {
        setTimeout(function () {
            let svg = d3.select(chartID).select('svg');
            svg.selectAll(".focus").remove();
            let focus = svg.append("g")
                .attr("class", "DisplayNone focus focusWOne");
                // .style("display", "none");

            focus.append("circle")
                .attr("r", 5);


            if (customChartControlData.bottomTooltipLabel == 'Va' || customChartControlData.bottomTooltipLabel == 'Ia') {
                focus.append("rect")
                    .attr("class", "tooltipCustom")
                    .attr("width", 100)
                    .attr("height", 58)
                    .attr("x", 10)
                    .attr("y", -28)
                    .attr("rx", 0)
                    .attr("ry", 0);

                focus.append("text")
                    .attr("class", "tooltip-date")
                    .attr("x", 60)
                    .attr("y", -15);

                focus.append("text")
                    .attr("class", "labelTop")
                    .attr("x", 18)
                    .attr("y", -15)
                    .text(customChartControlData && customChartControlData.topTooltipLabel ? `${customChartControlData.topTooltipLabel} ` : "Time ");

                focus.append("text")
                    .attr("class", "labelTopEqual")
                    .attr("x", 18)
                    .attr("y", -15)
                    .text("=");

                focus.append("text")
                    .attr("class", "labelLike")
                    .attr("x", 18)
                    .attr("y", -2)
                    .text(customChartControlData && customChartControlData.bottomTooltipLabel ? `${customChartControlData.bottomTooltipLabel} ` : "Point ");

                focus.append("text")
                    .attr("class", "labelLikeEqual")
                    .attr("x", 18)
                    .attr("y", -2)
                    .text("=");

                focus.append("text")
                    .attr("class", "tooltip-likes")
                    .attr("x", 60)
                    .attr("y", -2);

                focus.append("text")
                    .attr("class", "labelLike2")
                    .attr("x", 18)
                    .attr("y", 11)
                    .text(customChartControlData && customChartControlData.bottomTooltipLabel2 ? `${customChartControlData.bottomTooltipLabel2} ` : "Point ");

                focus.append("text")
                    .attr("class", "labelLikeEqual2")
                    .attr("x", 18)
                    .attr("y", 11)
                    .text("=");

                focus.append("text")
                    .attr("class", "tooltip-likes2")
                    .attr("x", 60)
                    .attr("y", 11);

                focus.append("text")
                    .attr("class", "labelLike3")
                    .attr("x", 18)
                    .attr("y", 24)
                    .text(customChartControlData && customChartControlData.bottomTooltipLabel3 ? `${customChartControlData.bottomTooltipLabel3} ` : "Point ");

                focus.append("text")
                    .attr("class", "labelLikeEqual3")
                    .attr("x", 18)
                    .attr("y", 24)
                    .text("=");

                focus.append("text")
                    .attr("class", "tooltip-likes3")
                    .attr("x", 60)
                    .attr("y", 24);

            }
            else {
                focus.append("rect")
                    .attr("class", "tooltipCustom")
                    .attr("width", 100)
                    .attr("height", 40)
                    .attr("x", 10)
                    .attr("y", -12)
                    .attr("rx", 0)
                    .attr("ry", 0);


                focus.append("text")
                    .attr("class", "tooltip-date")
                    .attr("x", 60)
                    .attr("y", 5);

                focus.append("text")
                    .attr("class", "labelTop")
                    .attr("x", 18)
                    .attr("y", 5)
                    .text(customChartControlData && customChartControlData.topTooltipLabel ? `${customChartControlData.topTooltipLabel} ` : "Time ");

                focus.append("text")
                    .attr("class", "labelTopEqual")
                    .attr("x", 45)
                    .attr("y", 5)
                    .text("=");

                focus.append("text")
                    .attr("class", "labelLike")
                    .attr("x", 18)
                    .attr("y", 18)
                    .text(customChartControlData && customChartControlData.bottomTooltipLabel ? `${customChartControlData.bottomTooltipLabel} ` : "Point ");

                focus.append("text")
                    .attr("class", "labelLikeEqual")
                    .attr("x", 45)
                    .attr("y", 18)
                    .text("=");

                focus.append("text")
                    .attr("class", "tooltip-likes")
                    .attr("x", 60)
                    .attr("y", 18);
            }

            setTimeout(() => {
                try {
                    const svgAttribute = svg.node().getBBox();
                    const width = svg._groups[0][0].width.animVal.value;
                    const height = svg._groups[0][0].height.animVal.value;

                    if (document.getElementById(chartID.replace("#", '') + "Overlay")) {
                        document.getElementById(chartID.replace("#", '') + "Overlay").remove();
                    }

                    svg.append("rect")
                        .attr("id", chartID.replace("#", '') + "Overlay")
                        .attr("class", "overlay")
                        .attr("width", width)
                        .attr("height", height - 21)
                        .attr("y", 20)
                        .on("mouseover", function () {
                            focus.classed("DisplayBlock", false);
                            focusOne.classed("DisplayBlock", false);
                            focusTwo.classed("DisplayBlock", false);
                            focus.classed("DisplayNull", true);
                            focus.classed("DisplayNone", true);
                            focus.classed("DisplayNone", true);

                            // focus.style("display", null);
                            // focusOne.style("display", "none");
                            // focusTwo.style("display", "none");
                        })
                        .on("mouseout", function () {
                        focus.classed("DisplayBlock", false);
                        focusOne.classed("DisplayBlock", false);
                        focusTwo.classed("DisplayBlock", false);
                        focus.classed("DisplayNone", true);
                        focusOne.classed("DisplayNone", true);
                        focusTwo.classed("DisplayNone", true);

                            // focus.style("display", "none");
                            // focusOne.style("display", "none");
                            // focusTwo.style("display", "none");

                            getAllSyncChart().forEach((chart) => {
                                if (chart) {
                                    try {
                                        chart.__focus.classed("displayBlock",false);
                                        chart.__focusOne.classed("displayBlock", false);
                                        chart.__focusTwo.classed("displayBlock", false);
                                        chart.__focus.classed("displayNone", true);
                                        chart.__focusOne.classed("displayNone", true);
                                        chart.__focusTwo.classed("displayNone", true);

                                        // chart.__focus.style("display", "none");
                                        // chart.__focusOne.style("display", "none");
                                        // chart.__focusTwo.style("display", "none");
                                    } catch (e) {
                                        //
                                    }
                                }
                            });
                        })
                        .on("mousemove", mousemove);
                } catch (error) {
                }
            }, 0);


            let focusOne = svg.append("g")
                .attr("class", "DisplayNone focus focusWTwo");
                // .style("display", "none");

            focusOne.append("circle")
                .attr("r", 5);

            let focusTwo = svg.append("g")
                .attr("class", "DisplayNone focus focusWThree");
                // .style("display", "none");

            focusTwo.append("circle")
                .attr("r", 5);

            mainChartInstanceInToolTip.__focus = focus;
            mainChartInstanceInToolTip.__focusOne = focusOne;
            mainChartInstanceInToolTip.__focusTwo = focusTwo;

            function mousemove() {
                let showToolTipOnLeft = false;
                let showToolTipOnTop = false;
                const width = svg._groups[0][0].width.animVal.value;
                const height = svg._groups[0][0].height.animVal.value;

                if (d3.mouse(this)[0] > width / 2) {
                    showToolTipOnLeft = true;
                }

                if (d3.mouse(this)[1] > height / 2) {
                    showToolTipOnTop = true;
                }

                console.log("showToolTipOnTop", showToolTipOnTop);

                showToolTip(d3.mouse(this)[0], focus, focusOne, focusTwo, showToolTipOnLeft, showToolTipOnTop);
                getAllSyncChart().forEach((chart) => {
                    if (chart) {
                        chart.__showToolTip(d3.mouse(this)[0], chart.__focus, chart.__focusOne, chart.__focusTwo, showToolTipOnLeft);
                    }
                });
            }
        }, 0);
    }

    function showToolTip(xPoint, focus, focusOne, focusTwo, showToolTipOnLeft, showToolTipOnTop) {
        try {
            focus.classed("DisplayNone",false);
            focusOne.classed("DisplayNone",false);
            focusTwo.classed("DisplayNone",false);
            focus.classed("DisplayBlock",true);
            focusOne.classed("DisplayBlock",true);
            focusTwo.classed("DisplayBlock",true);
            // focus.style("display", "block");
            // focusOne.style("display", "block");
            // focusTwo.style("display", "block");
        } catch (e) {
            //
        }

        let x0 = x.invert(xPoint),
            i = bisectDate(fullSeriesData[0], x0, 1),
            d0 = fullSeriesData[0][i - 1],
            d1 = fullSeriesData[0][i],
            d = x0 - d0 && d0.date > d1 && d1.date - x0 ? d1 : d0,
            dF1 = 0, dF2 = 0;

        if (fullSeriesData.length > 1) {

            let i1 = bisectDate(fullSeriesData[1], x0, 1),
                d01 = fullSeriesData[1][i1 - 1],
                d11 = fullSeriesData[1][i1];
            if (d01 && d11) {
                dF1 = x0 - d01.date > d11.date - x0 ? d11 : d01;
                if (focusOne) {
                    focusOne.attr("transform", "translate(" + x(dF1[_data.xDataAttribute]) + "," + y(dF1[_data.yDataAttribute]['value']) + ")");
                }
            }

        }

        if (fullSeriesData.length > 2) {
            let i2 = bisectDate(fullSeriesData[2], x0, 1),
                d02 = fullSeriesData[2][i2 - 1],
                d12 = fullSeriesData[2][i2];
            if (d02 && d12) {
                dF2 = x0 - d02.date > d12.date - x0 ? d12 : d02;
                if (focusTwo) {
                    focusTwo.attr("transform", "translate(" + x(dF2[_data.xDataAttribute]) + "," + y(dF2[_data.yDataAttribute]['value']) + ")");
                }
            }
        }


        try {
            focus.attr("transform", "translate(" + x(d[_data.xDataAttribute]) + "," + y(d[_data.yDataAttribute]['value']) + ")");
            focus.select(".tooltip-date").text(d[_data.xDataAttribute].toFixed(2));

            focus.select(".tooltip-likes").text(d[_data.yDataAttribute]["value"].toFixed(2));

            let xvalueLength = d[_data.xDataAttribute].toFixed(2).length;
            let tooltipDatePosition = (xvalueLength >= 5 ? 75 : 81);
            let yvalueLength = d[_data.yDataAttribute]["value"].toFixed(2).length;
            let yvalue = d[_data.yDataAttribute]["value"].toFixed(2)
            let tooltipLikesPosition;
            let tooltipLikesPosition2;
            let tooltipLikesPosition3;

            if (yvalueLength == 4) {
                tooltipLikesPosition = 81
            }
            else if (yvalue < 0 && yvalueLength == 5) {
                tooltipLikesPosition = 77
            }
            else if (yvalue >= 0 && yvalueLength == 5) {
                tooltipLikesPosition = 75
            }
            else if (yvalue < 0 && yvalueLength == 6) {
                tooltipLikesPosition = 71
            }
            else if (yvalue >= 0 && yvalueLength == 6) {
                tooltipLikesPosition = 69
            }
            else if (yvalue < 0 && yvalueLength == 7) {
                tooltipLikesPosition = 65
            }
            else if (yvalue >= 0 && yvalueLength == 7) {
                tooltipLikesPosition = 63
            }
            else {
                tooltipLikesPosition = 59
            }

            if (customChartControlData.bottomTooltipLabel == 'Va' || customChartControlData.bottomTooltipLabel == 'Ia') {

                focus.select(".tooltip-likes2").text(dF1[_data.yDataAttribute]['value'].toFixed(2));
                focus.select(".tooltip-likes3").text(dF2[_data.yDataAttribute]['value'].toFixed(2));
                let yvalueLength2 = dF1[_data.yDataAttribute]['value'].toFixed(2).length;
                let yvalueLength3 = dF2[_data.yDataAttribute]['value'].toFixed(2).length;
                let yvalue2 = dF1[_data.yDataAttribute]['value'].toFixed(2)
                let yvalue3 = dF2[_data.yDataAttribute]['value'].toFixed(2)

                if (yvalueLength2 == 4) {
                    tooltipLikesPosition2 = 81
                }
                else if (yvalue2 < 0 && yvalueLength2 == 5) {
                    tooltipLikesPosition2 = 77
                }
                else if (yvalue2 >= 0 && yvalueLength2 == 5) {
                    tooltipLikesPosition2 = 75
                }
                else if (yvalue2 < 0 && yvalueLength2 == 6) {
                    tooltipLikesPosition2 = 71
                }
                else if (yvalue2 >= 0 && yvalueLength2 == 6) {
                    tooltipLikesPosition2 = 69
                }
                else if (yvalue2 < 0 && yvalueLength2 == 7) {
                    tooltipLikesPosition2 = 65
                }
                else if (yvalue2 >= 0 && yvalueLength2 == 7) {
                    tooltipLikesPosition2 = 63
                }
                else {
                    tooltipLikesPosition2 = 59
                }

                if (yvalueLength3 == 4) {
                    tooltipLikesPosition3 = 81
                }
                else if (yvalue3 < 0 && yvalueLength3 == 5) {
                    tooltipLikesPosition3 = 77
                }
                else if (yvalue3 >= 0 && yvalueLength3 == 5) {
                    tooltipLikesPosition3 = 75
                }
                else if (yvalue3 < 0 && yvalueLength3 == 6) {
                    tooltipLikesPosition3 = 71
                }
                else if (yvalue3 >= 0 && yvalueLength3 == 6) {
                    tooltipLikesPosition3 = 69
                }
                else if (yvalue3 < 0 && yvalueLength3 == 7) {
                    tooltipLikesPosition3 = 65
                }
                else if (yvalue3 >= 0 && yvalueLength3 == 7) {
                    tooltipLikesPosition3 = 63
                }
                else {
                    tooltipLikesPosition3 = 59
                }
            }

            if (showToolTipOnLeft) {
                focus.select(".tooltipCustom").attr('x', 10 - 120);
                focus.select(".labelLike").attr("x", 18 - 120);
                focus.select(".labelLikeEqual").attr("x", 48 - 120);
                focus.select(".labelTop").attr("x", 18 - 120);
                focus.select(".tooltip-date").attr("x", tooltipDatePosition - 120);
                focus.select(".labelTopEqual").attr("x", 48 - 120);
                focus.select(".tooltip-likes").attr("x", tooltipLikesPosition - 120);

                if (customChartControlData.bottomTooltipLabel == 'Va' || customChartControlData.bottomTooltipLabel == 'Ia') {
                    focus.select(".labelLike2").attr("x", 18 - 120);
                    focus.select(".labelLike3").attr("x", 18 - 120);
                    focus.select(".labelLikeEqual2").attr("x", 48 - 120);
                    focus.select(".labelLikeEqual3").attr("x", 48 - 120);
                    focus.select(".tooltip-likes2").attr("x", tooltipLikesPosition2 - 120);
                    focus.select(".tooltip-likes3").attr("x", tooltipLikesPosition3 - 120);
                }
                /*
                if(showToolTipOnTop) {
                    focus.select(".tooltipCustom").attr('y', 10);
                    focus.select(".labelLike").attr("y", 18);
                    focus.select(".labelTop").attr("y", 18);
                    focus.select(".tooltip-date").attr("y", 60);
                    focus.select(".tooltip-likes").attr("y", 90);
                }
                 */
            } else {
                focus.select(".tooltipCustom").attr('x', 10);
                focus.select(".labelLike").attr("x", 18);
                focus.select(".labelLikeEqual").attr("x", 48);
                focus.select(".labelTop").attr("x", 18);
                focus.select(".tooltip-date").attr("x", tooltipDatePosition);
                focus.select(".labelTopEqual").attr("x", 48);
                focus.select(".tooltip-likes").attr("x", tooltipLikesPosition);

                if (customChartControlData.bottomTooltipLabel == 'Va' || customChartControlData.bottomTooltipLabel == 'Ia') {
                    focus.select(".labelLike2").attr("x", 18);
                    focus.select(".labelLike3").attr("x", 18);
                    focus.select(".labelLikeEqual2").attr("x", 48);
                    focus.select(".labelLikeEqual3").attr("x", 48);
                    focus.select(".tooltip-likes2").attr("x", tooltipLikesPosition2);
                    focus.select(".tooltip-likes3").attr("x", tooltipLikesPosition3);
                }
                /*
                if(showToolTipOnTop) {
                    focus.select(".tooltipCustom").attr('y', 10);
                    focus.select(".labelLike").attr("y", 18);
                    focus.select(".labelTop").attr("y", 18);
                    focus.select(".tooltip-date").attr("y", 60);
                    focus.select(".tooltip-likes").attr("y", 90);
                }
                 */
            }
        } catch (e) {
        }
    }

    var mainChart = fc.chartSvgCartesian(x, y)
        .yLabel(customChartControlData.yaxislabel)
        .xLabel(customChartControlData.xaxislabel)
        .yTickArguments([5])
        .plotArea(multiLine);

    const navigatorChart = fc.chartSvgCartesian(x.copy(), y.copy())
        .plotArea(multiLineNavigator);

    setInitialDomain();

    const render = (_chartData) => {

        //Redefining the chart range on sampling change.
        lineDrawer = getLineDrawer();
        allSeries = [...lineDrawer];
        multiLine = fc.seriesSvgMulti()
            .series(allSeries);

        mainChart = fc.chartSvgCartesian(x, y)
            .yLabel(customChartControlData.yaxislabel)
            .xLabel(customChartControlData.xaxislabel)
            .yTickArguments([5])
            .yOrient("left")
            .plotArea(multiLine);

        mainChart.__chartData = _chartData;
        mainChart.__renderChart = render;
        mainChart.__yExtent = yExtent;
        mainChart.__getChartInstance = getChartInstance;
        mainChart.__generateSamplerData = generateSamplerData;

        d3.select(chartID)
            .datum(_chartData.series)
            .call(mainChart);

        d3.select(navigatorChartID)
            .datum(chartData)
            .call(navigatorChart);
        makeXGridLines();
        makeYGridLines();
        insertToolTip(mainChart);
        mainChart.__showToolTip = showToolTip;

        insertClickableButtonInSVG();

        globalChartInstance = mainChart;

        return mainChart;
    }
    render(chartData);

    function getChartInstance() {
        return globalChartInstance;
    }

    mainChart.__showToolTip = showToolTip;
    mainChart.__getChartInstance = getChartInstance;
    mainChart.__chartData = chartData;
    mainChart.__renderChart = render;
    mainChart.__generateSamplerData = generateSamplerData;
    mainChart.__yExtent = yExtent;
    // makeXGridLines();
    // makeYGridLines();
    return mainChart;
}

window.BuildLineChart = BuildLineChartWave;
