import {push} from "connected-react-router";

export function clearSubNodeCondition(data) {
    return { type:'CLEAR_SUBNODE_CONDITION',data};
}

export function redirectToEquipmentPage(data){
    return function (dispatch) {
        dispatch (push('/equipment-portfolio'))
    };
}

export function logOut(data){
    return function (dispatch) {
        dispatch ({type:'LOG_OUT', data })
        dispatch (push('/login'))
    };
}