import $ from 'jquery';
import config from './config';

class TrendApi {

    static getTrendDataAll(token, serialNumber, dataPoints){

        return new Promise((resolve, reject) => {

            window.requestGetTrendDataAll = $.ajax({
                url: (dataPoints !== undefined && dataPoints !== null) ? config.baseURL + "locations/" + serialNumber + "/history?days="+ config.trendMaxDays +"&fields=" + dataPoints :  config.baseURL + "locations/" + serialNumber + "/history?days=" + config.trendMaxDays,
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                dataType: "json"
            });

            window.requestGetTrendDataAll.done(function (msg) {
                resolve(Object.assign({}, msg.data));
            });

            window.requestGetTrendDataAll.fail(function (jqXHR, textStatus) {
                resolve(Object.assign({}, {errorMessage: jqXHR.statusText}));
            });
        });
    }
    
    static trendDataForSubNode(token, serialNumber, channelName,days){
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url:config.baseURL + "locations/" + serialNumber+"/channels/"+channelName+"/history?days="+days,
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                dataType: "json"
            });

            request.done(function (msg) {
                resolve(Object.assign({}, msg.data));
            });

            request.fail(function (jqXHR, textStatus) {
                resolve(Object.assign({}, {errorMessage: jqXHR.statusText}));
            });
        });
    }


    static getTrendDataSample(token, serialNumber, dataPoints){

        return new Promise((resolve, reject) => {

            let request = $.ajax({
                url: (dataPoints !== undefined && dataPoints !== null) ? config.baseURL + "locations/" + serialNumber + "/history?days="+ config.trendInitialDays +"&fields=" + dataPoints :  config.baseURL + "locations/" + serialNumber + "/history?days="+ config.trendInitialDays,
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                dataType: "json"
            });

            request.done(function (msg) {
                resolve(Object.assign({}, msg.data));
            });

            request.fail(function (jqXHR, textStatus) {
                resolve(Object.assign({}, {errorMessage: jqXHR.statusText}));
            });
        });
    }

    static getTrendDataSamplePowerMetering(token, serialNumber, dataPoints){

        return new Promise((resolve, reject) => {

            let request = $.ajax({
                url: (dataPoints !== undefined && dataPoints !== null) ? config.baseURL + "locations/" + serialNumber + "/history?days="+ config.trendInitialDaysForPowerMetering +"&fields=" + dataPoints :  config.baseURL + "locations/" + serialNumber + "/history?days="+ config.trendInitialDaysForPowerMetering,
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                dataType: "json"
            });

            request.done(function (msg) {
                resolve(Object.assign({}, msg.data));
            });

            request.fail(function (jqXHR, textStatus) {
                resolve(Object.assign({}, {errorMessage: jqXHR.statusText}));
            });
        });
    }

    static getSpecificTrendData(token, serialNumber, dataType){

        return new Promise((resolve, reject) => {

            window.requestGetSpecificTrendData = $.ajax({
                url: config.baseURL + "locations/" + serialNumber + "/history?fields=" + dataType,
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                dataType: "json"
            });

            window.requestGetSpecificTrendData.done(function (msg) {
                console.log('ZXZXZX1 Trending', msg);
                resolve(Object.assign({}, msg.data));
            });

            window.requestGetSpecificTrendData.fail(function (jqXHR, textStatus) {
                resolve(Object.assign({}, {errorMessage: jqXHR.statusText}));
            });
        });
    }
}

export default TrendApi;