import React from "react";
import NumericLable from "react-pretty-numbers";
import config from "../../api/config";

let option = {
    justification: "R",
    locales: "en-AU",
    percentage: false,
    precision: config.datapoint_precision,
    wholenumber: null,
    commafy: true,
    title: true
};
export default function Excitation(props) {
    if (props.showContent === "SyncPowerMeter") {
        return (
            <div className="blueBoxH bdRadius table-responsive spmb30">
                <div className="specialWrapper">
                    <table className="table table-condensed borderless measureTble deltaH">
                        <thead>
                        <tr>
                            <th>Excitation</th>
                            <th>&nbsp;</th>
                            <th/>
                            <th/>

                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="4" className="mt3 dbBL"/>
                        </tr>
                        <tr>
                            <td className="valueTxt txt-left"><span className="adjusTxt">Field Voltage</span></td>
                            <td className="text-right">
                                                 <span className="goldBox splBdRadius splDesk">

                                                     {
                                                         props.fieldVoltage ?
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{props.fieldVoltage}</NumericLable></span> :
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign wid48">Volts</span>
                                                 </span>
                            </td>
                            <td>
                                                 <span>
                                                     <span
                                                         className="perSign">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                 </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="valueTxt txt-left"><span className="adjusTxt">Field Current</span></td>
                            <td className="text-right">
                                                 <span className="goldBox splBdRadius splDesk">

                                                     {
                                                         props.fieldCurrent !== null && props.fieldCurrent !== undefined ?
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{props.fieldCurrent}</NumericLable></span> :
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign wid48">Amps</span>
                                                 </span>
                            </td>
                            <td>
                                                 <span>
                                                     <span
                                                         className="perSign">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                 </span>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>

        );
    } else {
        return (
            <div className="blueBoxH bdRadius table-responsive spmb30">
                <div className="specialWrapper">
                    <table className="table table-condensed borderless measureTble deltaH">
                        <thead>
                        <tr>
                            <th>Excitation</th>
                            <th>&nbsp;</th>
                            <th>
                                <div className="triangleDown"/>
                            </th>
                            <th>
                                <div className="triangleTop"/>
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan="4" className="mt3 dbBL"/>
                        </tr>

                        <tr>
                            <td className="valueTxt txt-left"><span className="adjusTxt">Field Voltage</span></td>
                            <td className="text-right">
                                                 <span className="goldBox splBdRadius splDesk">

                                                     {
                                                         props.fieldVoltage ?
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{props.fieldVoltage}</NumericLable></span> :
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign wid48">Volts</span>
                                                 </span>
                            </td>
                            <td>
                                                 <span className="goldBox splBdRadius splDesk">

                                                     {
                                                         props.fieldVoltageDeltaLower !== null ?
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{props.fieldVoltageDeltaLower}</NumericLable></span> :
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                 </span>
                            </td>
                            <td>
                                                 <span className="goldBox splBdRadius splDesk">
                                                     {
                                                         props.fieldVoltageDeltaUpper !== null ?
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{props.fieldVoltageDeltaUpper}</NumericLable></span> :
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                 </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="valueTxt txt-left"><span className="adjusTxt">Field Current</span></td>
                            <td className="text-right">
                                                 <span className="goldBox splBdRadius splDesk">

                                                     {
                                                         props.fieldCurrent !== null && props.fieldCurrent !== undefined ?
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{props.fieldCurrent}</NumericLable></span> :
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign wid48">Amps</span>
                                                 </span>
                            </td>
                            <td>
                                                 <span className="goldBox splBdRadius splDesk">

                                                     {
                                                         props.fieldCurrentDeltaLower !== null && props.fieldCurrentDeltaLower !== undefined ?
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{props.fieldCurrentDeltaLower}</NumericLable></span> :
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                 </span>
                            </td>
                            <td>
                                                 <span className="goldBox splBdRadius splDesk">

                                                     {
                                                         props.fieldCurrentDeltaUpper !== null && props.fieldCurrentDeltaUpper !== undefined ?
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{props.fieldCurrentDeltaUpper}</NumericLable></span> :
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                 </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

}





