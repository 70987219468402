import React, {useRef , useState} from 'react';
import { Route, Switch, useHistory, Redirect } from 'react-router'; // react-router v4/v5
import LoginPage from "../login/LoginPage";
import RecoverPasswordPage from "../recoverPassword/RecoverPasswordPage";
import EquPortfolioPage from "../equPortfolio/EquPortfolioPage";
import MotorAnalysisPage from "../motorAnalysis/MotorAnalysisPage";
import PowerAnalysisPage from "../motorAnalysis/PowerAnalysisPage";
import PowerMeterPage from "../powerMeter/PowerMeterPage";
import PowerMeterAdminPage from "../powerMeter/PowerMeterAdminPage";
import ResetPasswordPage from "../resetPassword/ResetPasswordPage";
import WaveFormPageNew from "../waveFormNew/waveFormPageNew";
import TrendingAnalysisPageNew from "../trendingAnalysisNew/TrendingAnalysisPageNew";
import SubNodeMeterPage from "../powerMeter/subNodeMeterPage";
import {useLocation} from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import store from "../../store";
import { logOut } from "../../actions/logOffAction";
import Logout from '../Logout/Logout';
import { updateToken } from "../../actions/authoriseUser"
import ProtectedRoute from '../protectedRoute/ProtectedRoute';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import config from '../../api/config'

function getCookie(c_name) {
    if (document.cookie.length > 0) {
        let c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            let c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}

export default function App() {
    const idleTimerRef = useRef(null);
    let location = useLocation();
    

    /*
      ** if user did any activity  .
    */
    const onAction = (e) => {
        console.log('user did something', e);
    };

    /*
     ** if user does't have any activity from last two hours then user will be redirected to login page .
   */
    const onIdle = () => {
        store.dispatch(logOut());
    };

  
    let refreshExp = parseInt(CryptoAES.decrypt(getCookie('refreshExp').toString(), config.encrypt_key_1 + config.encrypt_key_2).toString(CryptoENC))
    
    // creating token expiry date
    if (isNaN(refreshExp)) {
        refreshExp = new Date(1)
        
    } else {
        refreshExp = new Date(refreshExp)
    }
    
    // Starting token update service
    if (refreshExp > new Date()) {
        
        if (window.updateTokenInterval === undefined) {
            store.dispatch(updateToken())
            window.updateTokenInterval = setInterval(() => {
                store.dispatch(updateToken())
            }, (1800) * 1000)
        }
    }

    return (
        <React.Fragment>
            {
                location.pathname !== "/" &&
                location.pathname !== "/login" &&
                location.pathname !== "/recoverPassword" ? <IdleTimer
                    ref={idleTimerRef}
                    element={document}
                    onIdle={onIdle}
                    onAction={onAction}
                    debounce={250}
                    stopOnIdle={true}
                    // timeout={1000 * 20}
                    timeout={1000 * 60 * 120}
                /> : null
            }

            <Switch>
                
                <Route exact path="/" render={(props) => { if (refreshExp < new Date()){ return <LoginPage /> } else {  return <Redirect to="/equipment-portfolio" /> } } }/>
                <Route exact path="/login" render={(props) => { if (refreshExp < new Date()){ return <LoginPage /> } else {  return <Redirect to="/equipment-portfolio" /> } } }/>
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/resetPassword" component={ResetPasswordPage} />
                

                <Route exact path="/recoverPassword" component={RecoverPasswordPage} />
                <ProtectedRoute exact path="/equipment-portfolio" component={EquPortfolioPage} refreshExp={refreshExp} />
                <ProtectedRoute exact path="/motor-analysis" component={MotorAnalysisPage} refreshExp={refreshExp} />
                <ProtectedRoute exact path="/power-analysis" component={PowerAnalysisPage} refreshExp={refreshExp} />
                <ProtectedRoute exact path="/powerMeterPage" component={PowerMeterPage} refreshExp={refreshExp} />
                
                <ProtectedRoute exact path="/waveForm-analysis" component={WaveFormPageNew} refreshExp={refreshExp} />
                <ProtectedRoute exact path="/trending-analysis" component={TrendingAnalysisPageNew} refreshExp={refreshExp} />
                <ProtectedRoute exact path="/power-metering" component={PowerMeterAdminPage} refreshExp={refreshExp} />
                <ProtectedRoute exact path="/subNode-power-metering" component={SubNodeMeterPage} refreshExp={refreshExp} /> 
            </Switch>
        </React.Fragment>
    );

    
}