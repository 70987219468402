import React, { Component } from 'react'
import { logOut } from "../../actions/logOffAction";
import store from '../../store';

export default class Logout extends Component {

  componentDidMount() {
    store.dispatch(logOut())
  }

  render() {
    return (
      <></>
    )
  }
}
