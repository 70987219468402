import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as viewPowerMeteringAction from "../../actions/viewPowerMetering";
import * as viewEquipmentPortfolio from "../../actions/viewEquipmentPortfolio";
import * as actionTrendForm from "../../actions/trendAnalysis";
import * as viewMoterAnalysis from "../../actions/viewMotorAnalysis";
import * as viewWaveForm from "../../actions/waveForm";
import TopNavigation from "../common/topNavigation";
import * as logOffAction from "../../actions/logOffAction";
import config from "../../api/config";
import NumericLable from "react-pretty-numbers";
import $ from "jquery";
import SecTopHeader from "../common/SecTopHeader";
import Options from "../common/more_options";

class SubNodeMeterPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  toggleOptions() {
    this.setState({ showOptions: !this.state.showOptions });
  }

  componentDidMount() {
    this.interval = setInterval(this.apiCallProcess, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  apiCallProcess = () => {
    this.props.actionViewPowerMetering.loadSubNodeMeterPage(
      this.props.serialNumberForSubnode,
      true
    );
  };

  moveTrendingPage = channelData => {
    $(".loaderPosition").removeClass("hidden");
      this.props.actionTrendForm.getDataForSubNode(
          this.props.serialNumberForSubnode,
          channelData
      );
  };

  logOff = () => {
    this.props.logOffAction.logOut();
  };

  render() {
    let option = {
      justification: "R",
      locales: "en-AU",
      percentage: false,
      precision: config.datapoint_precision,
      wholenumber: null,
      commafy: true,
      title: true
    };
    return (
      <div className="reportPage">
        <div className="wrapperH">
          <div className="loginH clearfix">
            <div className="logosmallH">
              <a href="#">&nbsp;</a>
            </div>
            <div className="logoff ">
              <div className="three_line" onClick={this.toggleOptions.bind(this)}>&nbsp;</div>
            </div>
          </div>

          <div className="clearfix">
            <div>
              {this.state.showOptions ?
                <div className="options_menu">
                  <Options />
                </div>
                : null}
            </div>
            <TopNavigation
              activeNode={2}
              hideInfo={true}
              hidePowerMeteringIcon={this.props.topNavIconDecide}
            />
            <SecTopHeader name={this.props.subNodeData.name} serialNumber={this.props.subNodeData.serialNumber} facilityName={this.props.subNodeData.facilityName}/>
            <div className="blueBoxH bdRadius">
              <div className="titleH text-center clearfix">
                <div className="subTitleH text-center">
                  <span className="dbBL allCaps flNone txt-center">
                    Sub-Node Metering
                  </span>
                </div>
                <div className="dateTimeH">
                  <span className="waveHdRight">
                    <span className="spldbBL">updated: </span>{" "}
                    {this.props.subNodeData.displayTimestamp !== null ? this.props.subNodeData.displayTimestamp
                      : "---"}
                  </span>
                </div>
              </div>
              {this.props.subNodeData.channelData !== undefined &&  this.props.subNodeData.channelData.length > 0
                  ? this.props.subNodeData.channelData.map((channelData) => {
                    return (
                      <div className="greenBox bdRadius">
                        <div
                          className="roundCircle"
                          onClick={e => this.moveTrendingPage(channelData)}
                        >
                          <div className="trendAnaly"/>
                        </div>
                        <div className="table-responsive spmb30 splWidthForSubNode">
                          <div className="specialWrapper">
                            <table className="table table-condensed borderless measureTble deltaH imbalancH">
                              <tbody>
                                <tr>
                                  <td colSpan="4" className="mt3 dbBL"/>
                                </tr>
                                <tr>
                                  <td colSpan="4" className="mt3 dbBL"/>
                                </tr>
                                <tr>
                                  <td colSpan="4" className="mt3 dbBL"/>
                                </tr>
                                <tr>
                                  <td colSpan="4" className="mt3 dbBL"/>
                                </tr>
                                <tr>
                                  <td className="valueTxt txt-left">
                                    <span className="adjusTxt paddLeftForNdeName ">
                                      {channelData.name !== null ?
                                        channelData.name
                                        : "---"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="goldBox splBdRadius splCenterDesk">
                                      <span className="defaultAlert defaultAlertforSubNode">
                                        <span className="maAlert target_element15">
                                          &nbsp;
                                        </span>
                                      </span>
                                      {channelData.voltage !== null ? (
                                        <span className="greenTxt splpdLR">
                                          <NumericLable params={option}>
                                            {channelData.voltage.toFixed(2)}
                                          </NumericLable>
                                        </span>
                                      ) : (
                                        <span className="greenTxt splpdLR">
                                          <NumericLable params={option}>
                                            {"---"}
                                          </NumericLable>
                                        </span>
                                      )}
                                      <span className="perSign wid26">
                                        Volts
                                      </span>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="goldBox splBdRadius splCenterDesk">
                                      <span className="defaultAlert defaultAlertforSubNode">
                                        <span className="maAlert target_element15">
                                          &nbsp;
                                        </span>
                                      </span>
                                      {channelData.current !== null ? (
                                        <span className="greenTxt splpdLR paddLeftForNdeName">
                                          <NumericLable params={option}>
                                            {channelData.current.toFixed(2)}
                                          </NumericLable>
                                        </span>
                                      ) : (
                                        <span className="greenTxt splpdLR">
                                          <NumericLable params={option}>
                                            {"---"}
                                          </NumericLable>
                                        </span>
                                      )}
                                      <span className="perSign wid26">
                                        Amps
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="4" className="mt3 dbBL"/>
                                </tr>
                                <tr>
                                  <td>
                                    <span className="goldBox splBdRadius splCenterDesk">
                                      <span className="defaultAlert defaultAlertforSubNode">
                                        <span className="maAlert target_element15">
                                          &nbsp;
                                        </span>
                                      </span>
                                      {channelData.power !== null ? (
                                        <span className="greenTxt splpdLR">
                                          <NumericLable params={option}>
                                            {channelData.powerKw.toFixed(2)}
                                          </NumericLable>
                                        </span>
                                      ) : (
                                        <span className="greenTxt splpdLR">
                                          <NumericLable params={option}>
                                            {"---"}
                                          </NumericLable>
                                        </span>
                                      )}

                                      <span className="perSign wid26">kW</span>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="goldBox splBdRadius splCenterDesk">
                                      <span className="defaultAlert defaultAlertforSubNode">
                                        <span className="maAlert target_element15">
                                          &nbsp;
                                        </span>
                                      </span>
                                      {channelData.energy !== null ? (
                                        <span className="greenTxt splpdLR">
                                          <NumericLable params={option}>
                                            {channelData.energyKwh.toFixed(2)}
                                          </NumericLable>
                                        </span>
                                      ) : (
                                        <span className="greenTxt splpdLR">
                                          <NumericLable params={option}>
                                            {"---"}
                                          </NumericLable>
                                        </span>
                                      )}
                                      <span className="perSign wid26">kWh</span>
                                    </span>
                                  </td>
                                  <td>
                                    <span className="goldBox splBdRadius splCenterDesk">
                                      <span className="defaultAlert defaultAlertforSubNode">
                                        <span className="maAlert target_element15">
                                          &nbsp;
                                        </span>
                                      </span>
                                      {channelData.powerFactor !== null ? (
                                        <span className="greenTxt splpdLR">
                                          <NumericLable params={option}>
                                            {channelData.powerFactor.toFixed(2)}
                                          </NumericLable>
                                        </span>
                                      ) : (
                                        <span className="greenTxt splpdLR">
                                          <NumericLable params={option}>
                                            {"---"}
                                          </NumericLable>
                                        </span>
                                      )}

                                      <span className="perSign wid26">PF</span>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  :null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state.application;
};

const mapDispatchToProps = dispatch => {
  return {
    actionViewEquipmentPortfolio: bindActionCreators(
        viewEquipmentPortfolio,
        dispatch
    ),
    actionViewPowerMetering: bindActionCreators(
        viewPowerMeteringAction,
        dispatch
    ),
    actionTrendForm: bindActionCreators(actionTrendForm, dispatch),
    actionViewMotorAnalysis: bindActionCreators(viewMoterAnalysis, dispatch),
    logOffAction: bindActionCreators(logOffAction, dispatch),
    actionWaveFrom: bindActionCreators(viewWaveForm, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubNodeMeterPage);
