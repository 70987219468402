export default {
  token: '',
  refreshToken: '',
  sessionToken: '',
  selectedSerialNumber:'',
  facilityId:'',
  selectedName:'',
  portfolio: [],
  portfolioChild: [],
  metering: {},
  analysis: {},
  waveData: {},
  errorOccured: false,
  errorMessage:'',
  trendData: {historyFieldList:[]},
  trendDataOnDemand: {},
  chartLoaded:false,
  newtrendDataForPowerMeter:{id:-1},
  interval:-1,
  interval2:-1,
  allInterval: [],
  reSetTheRefershingOnPowerMeteringPage: false,
  subNodeData: {},
  comingSource:""
};
