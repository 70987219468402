import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import userApi from '../../api/user';
import $ from 'jquery';
import {bindActionCreators} from 'redux';
import * as authoriseUserAction from '../../actions/authoriseUser';
import './loginPage.css'
class LoginPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            eyeCSS: 'eyeSlash',
            passwordInputType: 'password'
        };
    }
    
    componentDidMount() {
        clearInterval(window.updateTokenInterval)
        window.updateTokenInterval = undefined

        
    }

    redirectToEquPortfolioPage = () => {
        if(this.props.state.password === undefined || this.props.state.name.length < 1){
            if(this.refs.container !== undefined && this.refs.container !== null) {
                this.refs.container.info(
                    "",
                    "Please provide proper user name", {
                        timeOut: 5000,
                        preventDuplicates: true,
                        closeButton: true
                    });
            }
            return;
        }

        if(this.props.state.password.length < 1){
            if(this.refs.container !== undefined && this.refs.container !== null) {
                this.refs.container.info(
                    "",
                    "Please provide valid password", {
                        timeOut: 5000,
                        preventDuplicates: true,
                        closeButton: true
                    });
            }
            return;
        }

        $('.loaderPosition').removeClass('hidden');
        console.log(this.props.sessionDuration)
        userApi.login(this.props.state.name, this.props.state.password, global.sessions.sessionDuration).then(data => {
            
            if (data.data.customerCode === 'system') {
                if (data.data.featureList.length) {
                    window.location = data.data.featureList[0].value
                }
            }
            else {
                this.props.action.validateUser(data);
                
         

                //adding value to global variable for taking customer name and announcement value from sessions tab 
                global.sessions.customerName = data.data.customerName;
                global.sessions.announcement = data.data.announcement.trim();
                global.sessions.announcement_flag = true;                                           //flag used to display the popup on login only once.
                global.sessions.userId = data.data.firstName;
                global.sessions.analyticsLink_flag = false;                                         //flag should false for every login
                if (data.data.featureList.length) {
                    global.sessions.featureList_value = data.data.featureList[0].value;             //variable to pull value coloumn data from session/featurelist/value
                    global.sessions.featureList_property = data.data.featureList[0].property;       //variable to pull value coloumn data from session/featurelist/property
                    global.sessions.analyticsLink_flag = true;                                      //flag used to display the analytics link only once
                }
            }

        }).catch(error => {
            $('.loaderPosition').addClass('hidden');
            if(this.refs.container !== undefined && this.refs.container !== null) {
                this.refs.container.info(
                    "",
                    error, {
                        timeOut: 5000,
                        preventDuplicates: true,
                        closeButton: true
                    });
            }
        });


    };

    updateUser = (event) =>{
        const field = event.target.name;
        let user = this.props.state ;
        user[field] = event.target.value;
        return this.setState({currentUser: user});
    };

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.redirectToEquPortfolioPage();
        }
    };

    render() {
        console.log("LOGIN>>>>>>", this.props);
        let ReactToastr = require("react-toastr");
        let {ToastContainer} = ReactToastr;
        let ToastMessageFactory = React.createFactory(ReactToastr.ToastMessage.animation);

        const OnLoginDetailKeypress = (event) => {this.handleKeyPress(event);};
        const OnUserChange = (event) => {this.updateUser(event);};
        const OnEyeClick = () => {
            if (this.state.passwordInputType === 'password') {
                this.setState({
                    eyeCSS: 'eyeBtn',
                    passwordInputType: 'text'
                })
            }
            else {
                this.setState({
                    eyeCSS: 'eyeSlash',
                    passwordInputType: 'password'
                })
            }
        }
        return (
            <div className="loginPage">

                <ToastContainer ref="container"
                                toastMessageFactory={ToastMessageFactory}
                                className="toast-top-right" />

                <div className="wrapperH">
                    <div className="loginH">
                        <div className="logoBigH">
                            <a href="javascript:void(0);" >&nbsp;</a>
                        </div>
                    </div>
                    <div className="loginDetails" onKeyPress={OnLoginDetailKeypress}>
                        <div className="uNameH mb20">
                            <span className="labelH">User ID:</span>
                            <span>
                            <input type="text" className="inputBox bdRadius" name="name" value={this.props.state.name} onChange={OnUserChange} />
                            </span>
                        </div>
                        <div className="uNameH">
                            <span className="labelH">Password:</span>
                            
                            <input type={this.state.passwordInputType} className="inputBox bdRadius test" name="password" value={this.props.state.password} onChange={OnUserChange}/>
                            <button className={this.state.eyeCSS} onClick={OnEyeClick}><label></label> </button>

                        </div>
                        
                        <div className="clearfix mb35">
                    
                            <Link to="/recoverPassword" className="forgotLink">Forgot your Password?</Link>
                            <text className='supportLinkLogin'>For additional help email:&nbsp;</text>
                            <a href='mailto:support@voltainsite.com' className='supportEmailLogin'>support@voltainsite.com</a>
                        </div>
                        <div className="continueBtnH">
                            <input type="button" value="Continue" className="continueBtn bdRadius" onClick={this.redirectToEquPortfolioPage} />
                        </div
                        >
                    </div>
                </div>

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {state: {currentUser:{}}};
};

const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators(authoriseUserAction, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
