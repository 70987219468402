import React from "react";
import NumericLable from "react-pretty-numbers";
import config from "../../api/config";
//test
let option = {
    justification: "R",
    locales: "en-AU",
    percentage: false,
    precision: config.datapoint_precision,
    wholenumber: null,
    commafy: true,
    title: true
};
export default function DCMotor(props) {
    return (
        <div className="blueBoxH bdRadius table-responsive spmb30">
            <div className="specialWrapper">
                <table className="table table-condensed borderless measureTble deltaH">
                    <thead>
                    <tr>
                        <th>DC Motor</th>
                        <th>&nbsp;</th>
                        <th>
                            <div className="triangleDown"/>
                        </th>
                        <th>
                            <div className="triangleTop"/>
                        </th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colSpan="4" className="mt3 dbBL"/>
                    </tr>
                    <tr>
                        <td className="valueTxt txt-left"><span className="adjusTxt">DC Voltage</span></td>
                        <td className="text-right">
                                                 <span className="goldBox splBdRadius splDesk">

                                                     {
                                                         props.dcVoltage ?
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{props.dcVoltage}</NumericLable></span> :
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign wid48">Volts</span>
                                                 </span>
                        </td>
                        <td>
                                                 <span className="goldBox splBdRadius splDesk">

                                                     {
                                                         props.dcVoltageDeltaLower !== null && props.dcVoltageDeltaLower !== undefined ?
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{props.dcVoltageDeltaLower}</NumericLable></span> :
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                 </span>
                        </td>
                        <td>
                                                 <span className="goldBox splBdRadius splDesk">
                                                     {
                                                         props.dcVoltageDeltaUpper !== null && props.dcVoltageDeltaUpper !== undefined ?
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{props.dcVoltageDeltaUpper}</NumericLable></span> :
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                 </span>
                        </td>
                    </tr>
                    <tr>
                        <td className="valueTxt txt-left"><span className="adjusTxt">DC Current</span></td>
                        <td className="text-right">
                                                 <span className="goldBox splBdRadius splDesk">

                                                     {
                                                         props.dcCurrent !== null && props.dcCurrent !== undefined ?
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{props.dcCurrent}</NumericLable></span> :
                                                             <span className="greenTxt  text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign wid48">Amps</span>
                                                 </span>
                        </td>
                        <td>
                                                 <span className="goldBox splBdRadius splDesk">

                                                     {
                                                         props.dcCurrentDeltaLower !== null && props.dcCurrentDeltaLower !== undefined ?
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{props.dcCurrentDeltaLower}</NumericLable></span> :
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                 </span>
                        </td>
                        <td>
                                                 <span className="goldBox splBdRadius splDesk">

                                                     {
                                                         props.dcCurrentDeltaUpper !== null && props.dcCurrentDeltaUpper !== undefined ?
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{props.dcCurrentDeltaUpper}</NumericLable></span> :
                                                             <span className="greenTxt text-right"><NumericLable
                                                                 params={option}>{"---"}</NumericLable></span>
                                                     }

                                                     <span className="perSign">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                 </span>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    );

}





