import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as viewPowerMeteringAction from "../../actions/viewPowerMetering";
import * as viewEquipmentPortfolio from "../../actions/viewEquipmentPortfolio";
import * as viewMoterAnalysis from "../../actions/viewMotorAnalysis";
import * as viewWaveForm from "../../actions/waveForm";
import NumericLable from "react-pretty-numbers";
import TopNavigation from "../common/topNavigation";
import config from "../../api/config";
import * as logOffAction from "../../actions/logOffAction";
import SecTopHeader from "../common/SecTopHeader";
import Excitation from "../common/ExcitationComponent";
import BuildLineChart from "../../chart/linePowermeter";
import Options from "../common/more_options";

class PowerMeterAdminPage extends React.Component {
    constructor(props) {
        super(props);
        window.chartRefreshInterval = null;
        this.chartLoaded = false;
        this.dataSelectedNode = {};
        this.state = {
            equipmentOfFacility: {},
            chartClass: "hidden",
            loaderClass: "",
            showOptions: false,
        };
    }

    toggleOptions() {
        this.setState({ showOptions: !this.state.showOptions });
    }

    componentDidMount() {
        window.powerMeterAdminPageInterval = setInterval(this.refreshPage, 1000);

        this.timeOutOne = setTimeout(
            function () {
                this.setState({
                    loaderClass: "hidden",
                    chartClass: "",
                });

                if (this.chartLoaded === false) {
                    //this.dataSelectedNode = JSON.parse(JSON.stringify(this.props.newtrendDataForPowerMeter));
                    this._chartRenderer([], "");
                }
            }.bind(this),
            15000
        );

        setTimeout(function () {
            $(".loaderPosition").addClass("hidden");
        }, 500);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedName !== this.props.selectedName) {
            window.scrollTo(0, 0);
            setTimeout(function () {
                $(".loaderPosition").addClass("hidden");
            }, 1000);
        }

        if (
            nextProps.metering.showAveragePowerModule &&
            nextProps.newtrendDataForPowerMeter.id !== -1 &&
            nextProps.newtrendDataForPowerMeter.power !== undefined
        ) {
            this.chartLoaded = true;
            this._chartRenderer(
                nextProps.newtrendDataForPowerMeter.power,
                "power",
                nextProps.newtrendDataForPowerMeter.timezone
            );
        }
    }

    componentWillUnmount() {
        clearInterval(window.chartRefreshInterval);
        clearInterval(window.powerMeterAdminPageInterval);
        clearTimeout(this.timeOutOne);
    }

    _chartRenderer = (seriesData, yDataAttribute, timezone) => {
        const customChartControlData = {
            scaleType: "",
            color: "#4990e2",
            topTooltipLabel: "Time",
            bottomTooltipLabel: "power",
            yaxislabel: "Power kW",
            hideToolTip: false,
            rangeButtons: [],
            timezone: timezone,
        };
        BuildLineChart(
            { series: [seriesData], xDataAttribute: "date", yDataAttribute: yDataAttribute },
            "#main-chartPM",
            "#navigator-chart-new-version",
            customChartControlData,
            function () {
                return [];
            },
            0,
            0
        );
    };

    refreshPage = () => {
        this.props.actionViewPowerMetering.loadPowerMeteringAdmin(undefined);
    };

    refreshPageCall = () => {
        return function () {
            if (window.powerMeterAdminPageInterval) {
                clearInterval(window.powerMeterAdminPageInterval);
            }

            setTimeout(
                function () {
                    window.powerMeterAdminPageInterval = setInterval(this.refreshPage, 1000);
                }.bind(this),
                0
            );
        }.bind(this);
    };

    logOff = () => {
        this.props.logOffAction.logOut();
    };

    applyClassName = (name) => {
        if (name !== undefined && name.length > 15) {
            return "eqType eqTypesplmar ";
        } else {
            return "eqType ";
        }
    };

    render() {
        console.log("this.props.newtrendDataForPowerMeter", this.props.newtrendDataForPowerMeter);
        console.log("POWER METER PAGE ADMIN", this.props.metering);
        let option = {
            justification: "R",
            locales: "en-AU",
            percentage: false,
            precision: config.datapoint_precision,
            wholenumber: null,
            commafy: true,
            title: true,
        };

        let optionCeil = {
            justification: "R",
            locales: "en-AU",
            percentage: false,
            precision: config.datapoint_precision,
            commafy: true,
            title: true,
            wholenumber: "ceil",
        };

        console.log("this.props.portfolio", this.props.portfolio);

        return (
            <div className="reportPage">
                <div className="wrapperH">
                    <div className="loginH clearfix">
                        <div className="logosmallH">
                            <a href="#">&nbsp;</a>
                        </div>
                        <div className="logoff ">
                            <div className="three_line" onClick={this.toggleOptions.bind(this)}>
                                &nbsp;
                            </div>
                        </div>
                    </div>

                    <div className="clearfix">
                        <div>
                            {this.state.showOptions && (
                                <div className="options_menu">
                                    <Options />
                                </div>
                            )}
                        </div>
                        <TopNavigation activeNode={2} />
                        <SecTopHeader
                            name={this.props.metering.name}
                            serialNumber={this.props.metering.serialNumber}
                            secondarySerialNumber={this.props.metering.fieldSerialNumber}
                            facilityName={this.props.metering.facilityName}
                        />
                        <div className="blueBoxH bdRadius">
                            <div className="titleH text-center clearfix">
                                <div className="subTitleH text-center statusTitleContainer">
                                    {this.props.metering.fieldSerialNumber ? (
                                        <div className="connectedStatusContainer">
                                            <div
                                                className={
                                                    "status" +
                                                    (this.props.metering.connectedStatus ?? "")
                                                        .replace("ON", "green")
                                                        .replace("OFFLINE", "gray") +
                                                    " connectedStatusPrimary"
                                                    // this.props.node.status.toLowerCase(.)
                                                }
                                            ></div>
                                            <div
                                                className={
                                                    "status" +
                                                    (this.props.metering.fieldConnectedStatus ?? "")
                                                        .replace("ON", "green")
                                                        .replace("OFFLINE", "gray") +
                                                    " connectedStatusSecondary"
                                                }
                                            ></div>
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                "newEqStateKW status" +
                                                (this.props.metering.connectedStatus ?? "")
                                                    .replace("ON", "green")
                                                    .replace("OFFLINE", "gray") +
                                                " newEqState eqKwStatus connectedStatusBase"
                                                // this.props.node.status.toLowerCase(.)
                                            }
                                        ></div>
                                    )}
                                    <span className="dbBL allCaps flNone txt-center">POWER METERING</span>
                                </div>
                                <div className="dateTimeH">
                                    <span className="waveHdRight">
                                        <span className="spldbBL">updated: </span>{" "}
                                        {this.props.metering.displayTimestamp}
                                    </span>
                                </div>
                            </div>

                            <div className="clearfix">
                                <div className="specialWrapper">
                                    <div className="meterH">
                                        <div className="padLefRigH spPb40">
                                            <div className="rectH ">
                                                <ul>
                                                    <li>
                                                        <span className="rectHead minMl50">Voltage</span>
                                                        <span className="goldBox dbi splBdRadius">
                                                            <span className="greenTxt splPl1 minWid75">
                                                                <NumericLable params={option}>
                                                                    {this.props.metering.voltage ?? "---"}
                                                                </NumericLable>
                                                            </span>
                                                            <span className="perSign wid26">Volts</span>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span className="rectHead minMl10">Current</span>
                                                        <span className="goldBox dbi splBdRadius">
                                                            <span className="greenTxt splPl1 minWid75">
                                                                <NumericLable params={option}>
                                                                    {this.props.metering.current ?? "---"}
                                                                </NumericLable>
                                                            </span>
                                                            <span className="perSign wid26">Amps</span>
                                                        </span>
                                                    </li>
                                                    {this.props.metering.showPowerFactorInPlaceOfHp ? (
                                                        <li>
                                                            <span className="rectHead minMl10">Power Factor</span>
                                                            <span className="goldBox dbi splBdRadius">
                                                                <span className="greenTxt splPl1 minWid75">
                                                                    <NumericLable params={option}>
                                                                        {this.props.metering.powerFactor ?? "---"}
                                                                    </NumericLable>
                                                                </span>
                                                                <span className="perSign wid26">PF</span>
                                                            </span>
                                                        </li>
                                                    ) : (
                                                        <li>
                                                            <span className="rectHead minMl10">HP</span>
                                                            <span className="goldBox dbi splBdRadius">
                                                                <span className="greenTxt splPl1 minWid75">
                                                                    <NumericLable params={option}>
                                                                        {this.props.metering.hp ?? "---"}
                                                                    </NumericLable>
                                                                </span>
                                                                <span className="perSign wid26">HP</span>
                                                            </span>
                                                        </li>
                                                    )}
                                                    {this.props.metering.showLineFrequency && (
                                                        <li>
                                                            <span className="rectHead minMl50">Frequency</span>
                                                            <span className="goldBox dbi splBdRadius ">
                                                                <span className="greenTxt splPl1 minWid75">
                                                                    <NumericLable params={option}>
                                                                        {this.props.metering.lineFrequency ?? "---"}
                                                                    </NumericLable>
                                                                </span>
                                                                <span className="perSign wid26">Hz</span>
                                                            </span>
                                                        </li>
                                                    )}
                                                    {this.props.metering.facilityPortfolio?.id ==
                                                        "63e2bfc8abdd8e287378e0ed" && (
                                                        <li>
                                                            <span className="rectHead minMl50">Power Factor</span>
                                                            <span className="goldBox dbi splBdRadius ">
                                                                <span className="greenTxt splPl1 minWid75">
                                                                    <NumericLable params={option}>
                                                                        {this.props.metering.powerFactor ?? "---"}
                                                                    </NumericLable>
                                                                </span>
                                                                <span className="perSign wid26"></span>
                                                            </span>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>

                                            <div className="circleH">
                                                <div className="firstCir">
                                                    <div className="secCir">
                                                        <div className="thirdCir">
                                                            <div className="divposition">
                                                                <div
                                                                    className={this.applyClassName(
                                                                        this.props.metering.displayEquipmentType
                                                                    )}
                                                                >
                                                                    {this.props.metering.displayEquipmentType ?? ""}
                                                                </div>
                                                                <span className="goldBoxforInsideCircle splRadisForgoldBoxInsideCircle voltageFont mainPowerReading">
                                                                    <span className="dis">
                                                                        <NumericLable params={option}>
                                                                            {this.props.metering.powerKw ?? "---"}
                                                                        </NumericLable>
                                                                    </span>
                                                                    <span className="kwFont">kW</span>
                                                                </span>
                                                                {/* <div className="circlecontent">
                                                                    <span className="hourH">
                                                                        {this.props.metering.runningTimeDays ? (
                                                                            <NumericLable params={optionCeil}>
                                                                                {this.props.metering.runningTimeDays}
                                                                            </NumericLable>
                                                                        ) : (
                                                                            "---"
                                                                        )}
                                                                    </span>
                                                                    <span className="hrsTxt"> Days</span>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.metering.showPowerModule && (
                            <div className="blueBoxH bdRadius table-responsive spmb30">
                                <div className="specialWrapper">
                                    <table className="table table-condensed borderless measureTble deltaH equalCells">
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Active</th>
                                                <th>
                                                    <span className="deltaTxt">Apparent</span>
                                                </th>
                                                <th>
                                                    <span className="deltaTxt">Reactive</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="4" className="mt3 dbBL" />
                                            </tr>
                                            <tr>
                                                <td className="valueTxt txt-left ">
                                                    <span className="adjusTxt splFontForPap">Power</span>
                                                </td>
                                                <td className="text-right">
                                                    <span className="goldBox splBdRadius splDesk splWidhtForTable">
                                                        <span className="greenTxt text-right">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.powerKw ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="perSign wid48">kW</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="goldBox splBdRadius splDesk splWidhtForTable">
                                                        <span className="greenTxt text-right">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.powerKva ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="perSign wid48">kVA</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="goldBox splBdRadius splDesk splWidhtForTable">
                                                        <span className="greenTxt text-right">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.powerKvaR ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="perSign wid48">kVAR</span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" className="mt3 dbBL" />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {this.props.metering.showPhaseSplitModule && (
                            <div className="blueBoxH bdRadius table-responsive spmb30">
                                <div className="specialWrapper">
                                    <table className="table table-condensed borderless measureTble deltaH equalCells">
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>Phase Ab</th>
                                                <th>
                                                    <span className="deltaTxt">Phase Bc</span>
                                                </th>
                                                <th>
                                                    <span className="deltaTxt">Phase Ca</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="4" className="mt3 dbBL" />
                                            </tr>
                                            <tr>
                                                <td className="valueTxt txt-left ">
                                                    <span className="adjusTxt splFontForPap">Voltage</span>
                                                </td>
                                                <td className="text-right">
                                                    <span className="goldBox splBdRadius splDesk splWidhtForTable">
                                                        <span className="greenTxt text-right">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.voltage1 ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="perSign wid48">Volts</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="goldBox splBdRadius splDesk splWidhtForTable">
                                                        <span className="greenTxt text-right">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.voltage2 ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="perSign wid48">Volts</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="goldBox splBdRadius splDesk splWidhtForTable">
                                                        <span className="greenTxt text-right">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.voltage3 ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="perSign wid48">Volts</span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="valueTxt txt-left ">
                                                    <span className="adjusTxt splFontForPap">Current</span>
                                                </td>
                                                <td className="text-right">
                                                    <span className="goldBox splBdRadius splDesk splWidhtForTable">
                                                        <span className="greenTxt text-right">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.current1 ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="perSign wid48">Amps</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="goldBox splBdRadius splDesk splWidhtForTable">
                                                        <span className="greenTxt text-right">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.current2 ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="perSign wid48">Amps</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="goldBox splBdRadius splDesk splWidhtForTable">
                                                        <span className="greenTxt text-right">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.current3 ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="perSign wid48">Amps</span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="valueTxt txt-left">
                                                    <span className="adjusTxt splFontForPap">Power Factor</span>
                                                </td>
                                                <td className="text-right">
                                                    <span className="goldBox splBdRadius splDesk splWidhtForTable">
                                                        <span className="greenTxt text-right">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.powerFactor1 ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="perSign wid48">PF</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="goldBox splBdRadius splDesk splWidhtForTable">
                                                        <span className="greenTxt text-right">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.powerFactor2 ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="perSign wid48">PF</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="goldBox splBdRadius splDesk splWidhtForTable">
                                                        <span className="greenTxt text-right">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.powerFactor3 ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="perSign wid48">PF</span>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4" className="mt3 dbBL" />
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                        {this.props.metering.showFieldModule && (
                            <Excitation
                                showContent="SyncPowerMeter"
                                fieldCurrent={this.props.metering.fieldCurrent}
                                fieldVoltage={this.props.metering.fieldVoltage}
                            />
                        )}
                        {this.props.metering.showAveragePowerModule && (
                            <div className="blueBoxH bdRadius">
                                <div className="titleH text-center clearfix">
                                    <div className="subTitleH text-center">
                                        <span className="dbBL allCaps flNone txt-center">AVERAGE POWER</span>
                                    </div>
                                </div>
                                <div className="clearfix">
                                    <div className="specialWrapper">
                                        <div className="meterH">
                                            <div>
                                                <div className="mar15forinsidecontent">
                                                    <span className="avgFont avgHeader1">TODAY AVG</span>
                                                    <span className="goldBoxforPmdc splRadiusForGoldBoxforPowerM voltageFont avgmar">
                                                        <span className="dis minWid75">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.powerTodayAverageKw ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="voltsFonts">kW</span>
                                                    </span>
                                                    <span className="marbetweencontent avgHeader2">
                                                        <span className="avgFont dailyAvg">DAILY AVG</span>
                                                        <span className="goldBoxforPmdc splRadiusForGoldBoxforPowerM voltageFont">
                                                            <span className="dis minWid75">
                                                                <NumericLable params={option}>
                                                                    {this.props.metering.powerDailyAverageKw ?? "---"}
                                                                </NumericLable>
                                                            </span>
                                                            <span className="voltsFonts">kW</span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mar15forinsidecontent">
                                                    <span className="peakFont avgHeader1 ">TODAY PEAK</span>
                                                    <span className="goldBoxforPmdc splRadiusForGoldBoxforPowerM voltageFont">
                                                        <span className="dis minWid75">
                                                            <NumericLable params={option}>
                                                                {this.props.metering.powerTodayPeakKw ?? "---"}
                                                            </NumericLable>
                                                        </span>
                                                        <span className="voltsFonts">kW</span>
                                                    </span>
                                                    <span className="peakFont marbetweencontent avgHeader2">
                                                        DAILY PEAK
                                                        <span className="goldBoxforPmdc splRadiusForGoldBoxforPowerM voltageFont avgmar">
                                                            <span className="dis minWid75">
                                                                <NumericLable params={option}>
                                                                    {this.props.metering.powerDailyPeakKw ?? "---"}
                                                                </NumericLable>
                                                            </span>
                                                            <span className="voltsFonts">kW</span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="forGreeBox bdRadius">
                                                <div className="graphTitile">
                                                    <span className="sptitlecol">{this.props.selectedName}</span>
                                                    <span className="sp2ndtitle">POWER</span>
                                                </div>
                                                <div
                                                    id="main-chartPM"
                                                    className={
                                                        "chartContainerVoltage chartMargin chartContainerVoltagePM " +
                                                        this.state.chartClass
                                                    }
                                                />

                                                <div className={this.state.loaderClass}>
                                                    <div className="loaderPositionPWK">
                                                        <div
                                                            style={{ fill: "rgb(227, 227, 227);" }}
                                                            className="preloaderSize"
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                                                                <path
                                                                    transform="translate(2)"
                                                                    d="M 0 6.4912 V 25.5088 H 4 V 6.4912 Z"
                                                                >
                                                                    <animate
                                                                        attributeName="d"
                                                                        values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
                                                                        dur="1.2s"
                                                                        repeatCount="indefinite"
                                                                        begin="0"
                                                                        keyTimes="0;.2;.5;1"
                                                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
                                                                        calcMode="spline"
                                                                    />
                                                                </path>
                                                                <path
                                                                    transform="translate(8)"
                                                                    d="M 0 8.02838 V 23.9716 H 4 V 8.02838 Z"
                                                                >
                                                                    <animate
                                                                        attributeName="d"
                                                                        values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
                                                                        dur="1.2s"
                                                                        repeatCount="indefinite"
                                                                        begin="0.2"
                                                                        keyTimes="0;.2;.5;1"
                                                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
                                                                        calcMode="spline"
                                                                    />
                                                                </path>
                                                                <path
                                                                    transform="translate(14)"
                                                                    d="M 0 12 V 20 H 4 V 12 Z"
                                                                >
                                                                    <animate
                                                                        attributeName="d"
                                                                        values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
                                                                        dur="1.2s"
                                                                        repeatCount="indefinite"
                                                                        begin="0.4"
                                                                        keyTimes="0;.2;.5;1"
                                                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
                                                                        calcMode="spline"
                                                                    />
                                                                </path>
                                                                <path
                                                                    transform="translate(20)"
                                                                    d="M 0 12 V 20 H 4 V 12 Z"
                                                                >
                                                                    <animate
                                                                        attributeName="d"
                                                                        values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
                                                                        dur="1.2s"
                                                                        repeatCount="indefinite"
                                                                        begin="0.6"
                                                                        keyTimes="0;.2;.5;1"
                                                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
                                                                        calcMode="spline"
                                                                    />
                                                                </path>
                                                                <path
                                                                    transform="translate(26)"
                                                                    d="M 0 12 V 20 H 4 V 12 Z"
                                                                >
                                                                    <animate
                                                                        attributeName="d"
                                                                        values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
                                                                        dur="1.2s"
                                                                        repeatCount="indefinite"
                                                                        begin="0.8"
                                                                        keyTimes="0;.2;.5;1"
                                                                        keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
                                                                        calcMode="spline"
                                                                    />
                                                                </path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return Object.assign(
        {},
        {
            metering:
                state.application.metering.connectedStatus === "ON"
                    ? state.application.metering
                    : {
                          name: state.application.selectedName,
                          timezone: state.application.metering.timezone,
                          serialNumber: state.application.metering.serialNumber,
                          status:
                              state.application.metering.connectedStatus === "STARTUP"
                                  ? "CONNECTING"
                                  : state.application.metering.connectedStatus,
                          voltage: "",
                          current: "",
                          lineFrequency: "",
                          hp: "",
                          runningTime: "",
                          timestamp: 1493246823.657,
                      },
        },
        state.application
    );
}

function mapDispatchToProps(dispatch) {
    return {
        actionViewEquipmentPortfolio: bindActionCreators(viewEquipmentPortfolio, dispatch),
        actionViewPowerMetering: bindActionCreators(viewPowerMeteringAction, dispatch),
        actionViewMotorAnalysis: bindActionCreators(viewMoterAnalysis, dispatch),
        logOffAction: bindActionCreators(logOffAction, dispatch),
        actionWaveFrom: bindActionCreators(viewWaveForm, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerMeterAdminPage);
