import trendApi from '../api/trend';
import $ from 'jquery';
import config from "../api/config";
import {push} from "connected-react-router";

export function addInterval(data){
    return function (dispatch) {
        return dispatch({ type:'INTERVAL', data });
    };
}

export function clearInterval(){
    return function (dispatch) {
        return dispatch({ type:'CLEAR_INTERVAL', data:null });
    };
}

export function getDataForSubNode(serialNumberForSubnode,channelData){
    return function (dispatch, getState) {
        setTimeout(
            function () {
                let serialNumberV;
                let token = getState().application.token;
                if(serialNumberForSubnode === null || serialNumberForSubnode === undefined) {
                    serialNumberV = getState().application.serialNumberForSubnode;
                }else{
                    serialNumberV = serialNumberForSubnode;
                }
                function getData(){
                    $('.loaderPosition').removeClass('hidden');
                    trendApi.trendDataForSubNode(token, serialNumberV,channelData.channel,config.subNodeTrendInitial).then(data => {
                        if(data.errorMessage !== undefined && data.errorMessage.length > 0)
                        {
                            //$('.loaderPosition').addClass('hidden');
                            var error = {message: "NO DATA AVAILABLE", timestamp: Math.floor(Date.now() / 1000)};
                            return dispatch({type: 'ON_ERROR_OCCURRED', error});
                        }else{
                            trendApi.trendDataForSubNode(token, serialNumberV,channelData.channel,config.subNodeTrendMaxDays).then(data => {
                                if(data.errorMessage !== undefined && data.errorMessage.length > 0)
                                {
                                    $('.loaderPosition').addClass('hidden');
                                    var error = {message: "NO DATA AVAILABLE", timestamp: Math.floor(Date.now() / 1000)};
                                    return dispatch({type: 'ON_ERROR_OCCURRED', error});
                                }else{
                                    return dispatch({type: 'LOAD_TREND_FOR_SUBNODE_MAXDAYS', data, channelData});
                                }
                            }).catch(err => {
                                var error = {message: "NO DATA AVAILABLE", timestamp: Math.floor(Date.now() / 1000)};
                                return dispatch({type: 'ON_ERROR_OCCURRED', error});
                            });
                            dispatch({type: 'LOAD_TREND_FROM_FOR_SUBNODE', data, channelData});
                                dispatch(push('/trending-analysis'));
                        }
                    }).catch(err => {
                        var error = {message: "NO DATA AVAILABLE", timestamp: Math.floor(Date.now() / 1000)};
                        return dispatch({type: 'ON_ERROR_OCCURRED', error});
                    });
                }
                return getData();
            }, 0);
    };
}

export function loadTrendFormNew(selectedSerialNumber) {
    //return null
    return function (dispatch, getState) {
        $('.loaderPosition').addClass('hidden');
        return dispatch(push('/trending-analysis'));
    };
}

export function loadTrendForm1(selectedSerialNumber, dataPoint,channel,source) {
    console.log("107",selectedSerialNumber, dataPoint,channel)
    return function (dispatch, getState) {
       if(source === "SubNode"){
           setTimeout(
               function () {
                   //let serialNumberV;
                   let token = getState().application.token;
                   function getData(){
                       $('.loaderPosition').removeClass('hidden');
                       trendApi.trendDataForSubNode(token,selectedSerialNumber,channel.channel,config.subNodeTrendMaxDays).then(data => {
                           if(data.errorMessage !== undefined && data.errorMessage.length > 0)
                           {
                               $('.loaderPosition').addClass('hidden');
                               let dataError = {errorMessage : data.errorMessage};
                           }
                           else {
                               console.log('Trend Analysis Data', data);
                               return dispatch({type: 'LOAD_TREND_FROM1', data, selectedSerialNumber, dataPoint});
                           }
                       }).catch(error => {
                           if (error === undefined || error.httpStatusCode === 405) {
                               // Done with error
                           }else {
                               throw error;
                           }
                       });
                   }
                   return getData();
               }, 0);
       }else {
           setTimeout(
               function () {

                   let serialNumberV;
                   let token = getState().application.token;
                   console.log("serialNumberVserialNumberVserialNumberVserialNumberV",serialNumberV,selectedSerialNumber)
                   function getData(){

                       $('.loaderPosition').removeClass('hidden');

                       trendApi.getTrendDataAll(token, selectedSerialNumber.id, dataPoint).then(data => {
                           if(data.errorMessage !== undefined && data.errorMessage.length > 0)
                           {
                               $('.loaderPosition').addClass('hidden');
                               let dataError = {errorMessage : data.errorMessage};
                           }
                           else {
                               console.log('Trend Analysis Data', data);
                               return dispatch({type: 'LOAD_TREND_FROM1', data, selectedSerialNumber, dataPoint});
                           }
                       }).catch(error => {
                           if (error === undefined || error.httpStatusCode === 405) {
                               // Done with error
                           }else {
                               throw error;
                           }
                       });
                   }

                   return getData();

               }, 0);
       }


    };
}

export function loadTrendFormForPowerMeterPage(selectedSerialNumber, dataPoint) {
    return function (dispatch, getState) {

        setTimeout(
            function () {

                let serialNumberV;
                let token = getState().application.token;
                if(selectedSerialNumber === null || selectedSerialNumber === undefined) {
                    serialNumberV = getState().application.selectedSerialNumber;
                }else{
                    serialNumberV = selectedSerialNumber.serialNumber;
                }

                function getData(){

                    $('.loaderPosition').removeClass('hidden');

                    /*trendApi.getTrendDataAll(token, serialNumberV, dataPoint).then(data => {
                        if(data.errorMessage !== undefined && data.errorMessage.length > 0)
                        {
                            $('.loaderPosition').addClass('hidden');
                            let dataError = {errorMessage : data.errorMessage};
                        }
                        else {
                            console.warn('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ-YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY');
                            console.warn('Trend Analysis Data', data);
                            console.warn('selectedSerialNumber', getState().application.selectedSerialNumber);

                            if(getState().application.selectedSerialNumber === data.serialNumber) {
                                return dispatch({
                                    type: 'LOAD_TREND_POWER_METER_GRAPH',
                                    data,
                                    selectedSerialNumber,
                                    dataPoint
                                });
                            }
                        }
                    }).catch(error => {
                        if (error === undefined || error.httpStatusCode === 405) {
                            // Done with error
                        }else {
                            throw error;
                        }
                    });*/

                    return dispatch({
                        type: 'LOAD_TREND_POWER_METER_GRAPH_Full',
                        data: {},
                        selectedSerialNumber,
                        dataPoint
                    });

                }

                return getData();

            }, 0);
    };
}

export function loadTrendFormForPowerMeterPageSample(selectedSerialNumber, dataPoint) {
    return function (dispatch, getState) {

        setTimeout(
            function () {

                let serialNumberV;
                let token = getState().application.token;
                if(selectedSerialNumber === null || selectedSerialNumber === undefined) {
                    serialNumberV = getState().application.selectedSerialNumber;
                }else{
                    serialNumberV = selectedSerialNumber.serialNumber;
                }

                function getData(){

                    $('.loaderPosition').removeClass('hidden');

                    trendApi.getTrendDataSample(token, serialNumberV, dataPoint).then(data => {
                        if(data.errorMessage !== undefined && data.errorMessage.length > 0)
                        {
                            $('.loaderPosition').addClass('hidden');
                            let dataError = {errorMessage : data.errorMessage};
                        }
                        else {
                            console.warn('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ-YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY');
                            console.warn('Trend Analysis Data', data);
                            console.warn('selectedSerialNumber', getState().application.selectedSerialNumber);

                            if(getState().application.selectedSerialNumber === data.serialNumber) {
                                return dispatch({
                                    type: 'LOAD_TREND_POWER_METER_GRAPH',
                                    data,
                                    selectedSerialNumber,
                                    dataPoint
                                });
                            }
                        }
                    }).catch(error => {
                        if (error === undefined || error.httpStatusCode === 405) {
                            // Done with error
                        }else {
                            throw error;
                        }
                    });
                }

                return getData();

            }, 0);
    };
}

export function loadTrendForm2(selectedSerialNumber, dataPoint,channel,source) {
    return function (dispatch, getState) {
        if(source === "SubNode"){
            setTimeout(
                function () {
                   // let serialNumberV;
                    let token = getState().application.token;
                    function getData(){
                        $('.loaderPosition').removeClass('hidden');
                        trendApi.trendDataForSubNode(token,selectedSerialNumber,channel.channel,config.subNodeTrendMaxDays).then(data => {
                            if(data.errorMessage !== undefined && data.errorMessage.length > 0)
                            {
                                $('.loaderPosition').addClass('hidden');
                                let dataError = {errorMessage : data.errorMessage};
                            }
                            else {
                                console.log('Trend Analysis Data', data);
                                return dispatch({type: 'LOAD_TREND_FROM2', data, selectedSerialNumber, dataPoint});
                            }
                        }).catch(error => {
                            if (error === undefined || error.httpStatusCode === 405) {
                                // Done with error
                            }else {
                                throw error;
                            }
                        });
                    }
                    return getData();
                }, 0);
        }else {
            setTimeout(
                function () {

                    // let serialNumberV;
                     let token = getState().application.token;
                    // if(selectedSerialNumber === null || selectedSerialNumber === undefined) {
                    //     serialNumberV = getState().application.selectedSerialNumber;
                    // }else{
                    //     serialNumberV = selectedSerialNumber.serialNumber;
                    // }

                    function getData(){

                        $('.loaderPosition').removeClass('hidden');

                        trendApi.getTrendDataAll(token, selectedSerialNumber.id, dataPoint).then(data => {
                            if(data.errorMessage !== undefined && data.errorMessage.length > 0)
                            {
                                $('.loaderPosition').addClass('hidden');
                                let dataError = {errorMessage : data.errorMessage};
                            }
                            else {
                                console.log('Trend Analysis Data', data);
                                return dispatch({type: 'LOAD_TREND_FROM2', data, selectedSerialNumber, dataPoint});
                            }
                        }).catch(error => {
                            if (error === undefined || error.httpStatusCode === 405) {
                                // Done with error
                            }else {
                                throw error;
                            }
                        });
                    }

                    return getData();

                }, 0);
        }

    };
}

export function loadTrendForm3(selectedSerialNumber, dataPoint,channel,source) {
    return function (dispatch, getState) {
        if(source === "SubNode"){
            setTimeout(
                function () {
                    let serialNumberV;
                    let token = getState().application.token;
                    function getData(){
                        $('.loaderPosition').removeClass('hidden');
                        trendApi.trendDataForSubNode(token,selectedSerialNumber,channel.channel,config.subNodeTrendMaxDays).then(data => {
                            if(data.errorMessage !== undefined && data.errorMessage.length > 0)
                            {
                                $('.loaderPosition').addClass('hidden');
                                let dataError = {errorMessage : data.errorMessage};
                            }
                            else {
                                console.log('Trend Analysis Data', data);
                                return dispatch({type: 'LOAD_TREND_FROM3', data, selectedSerialNumber, dataPoint});
                            }
                        }).catch(error => {
                            if (error === undefined || error.httpStatusCode === 405) {
                                // Done with error
                            }else {
                                throw error;
                            }
                        });
                    }
                    return getData();
                }, 0);
        }else {
        setTimeout(
            function () {

               // let serialNumberV;
                let token = getState().application.token;
                // if(selectedSerialNumber === null || selectedSerialNumber === undefined) {
                //     serialNumberV = getState().application.selectedSerialNumber;
                // }else{
                //     serialNumberV = selectedSerialNumber.serialNumber;
                // }

                function getData(){

                    $('.loaderPosition').removeClass('hidden');

                    trendApi.getTrendDataAll(token, selectedSerialNumber.id, dataPoint).then(data => {
                        if(data.errorMessage !== undefined && data.errorMessage.length > 0)
                        {
                            $('.loaderPosition').addClass('hidden');
                            let dataError = {errorMessage : data.errorMessage};
                        }
                        else {
                            console.log('Trend Analysis Data', data);
                            return dispatch({type: 'LOAD_TREND_FROM3', data, selectedSerialNumber, dataPoint});
                        }
                    }).catch(error => {
                        if (error === undefined || error.httpStatusCode === 405) {
                            // Done with error
                        }else {
                            throw error;
                        }
                    });
                }

                return getData();

            }, 0);
        }
    };
}

export function loadTrendForm4(selectedSerialNumber, dataPoint,channel,source) {
    return function (dispatch, getState) {
        if(source === "SubNode"){
            setTimeout(
                function () {
                   // let serialNumberV;
                    let token = getState().application.token;
                    function getData(){
                        $('.loaderPosition').removeClass('hidden');
                        trendApi.trendDataForSubNode(token,selectedSerialNumber,channel.channel,config.subNodeTrendMaxDays).then(data => {
                            if(data.errorMessage !== undefined && data.errorMessage.length > 0)
                            {
                                $('.loaderPosition').addClass('hidden');
                                let dataError = {errorMessage : data.errorMessage};
                            }
                            else {
                                console.log('Trend Analysis Data', data);
                                return dispatch({type: 'LOAD_TREND_FROM4', data, selectedSerialNumber, dataPoint});
                            }
                        }).catch(error => {
                            if (error === undefined || error.httpStatusCode === 405) {
                                // Done with error
                            }else {
                                throw error;
                            }
                        });
                    }
                    return getData();
                }, 0);
        }else {
            setTimeout(
                function () {
                   // let serialNumberV;
                    let token = getState().application.token;
                    // if (selectedSerialNumber === null || selectedSerialNumber === undefined) {
                    //     serialNumberV = getState().application.selectedSerialNumber;
                    // } else {
                    //     serialNumberV = selectedSerialNumber.serialNumber;
                    // }

                    function getData() {

                        $('.loaderPosition').removeClass('hidden');

                        trendApi.getTrendDataAll(token, selectedSerialNumber.id, dataPoint).then(data => {
                            if (data.errorMessage !== undefined && data.errorMessage.length > 0) {
                                $('.loaderPosition').addClass('hidden');
                                let dataError = {errorMessage: data.errorMessage};
                            } else {
                                console.log('Trend Analysis Data', data);
                                return dispatch({type: 'LOAD_TREND_FROM4', data, selectedSerialNumber, dataPoint});
                            }
                        }).catch(error => {
                            if (error === undefined || error.httpStatusCode === 405) {
                                // Done with error
                            } else {
                                throw error;
                            }
                        });
                    }

                    return getData();

                }, 0);
        }
    };
}

export function RedirectToEquipmentPortfolio(){

    return function (dispatch) {
        dispatch({type: 'LOAD_EQUIPMENT_PORTFOLIO'});
    };
}
