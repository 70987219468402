import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as viewPowerMeteringAction from "../../actions/viewPowerMetering";
import * as actionViewEquipmentPortfolio from "../../actions/viewEquipmentPortfolio";
import * as logOffAction from "../../actions/logOffAction";
import EquipmentKW from "./EquipmentKW";
import config from "../../api/config";
import Options from "../common/more_options";
import * as authoriseUserAction from '../../actions/authoriseUser';


class EquPortfolioPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showOptions: false,
        };
    }

    toggleOptions() {
        this.setState({ showOptions: !this.state.showOptions });
    }
    togglePopup() {
        global.sessions.announcement_flag = false;
    }
    toggleanAlyticsLink() {
        global.sessions.analyticsLink_flag = false;
    }

    componentDidMount() {
        try {
            window.requestGetTrendDataAll.abort();
        } catch (e) {
            console.log("Already aborted");
        }

        if (window.equPortfolioPageInterval !== undefined) {
            clearTimeout(window.equPortfolioPageInterval);
        }
        if (window.updateTokenInterval === undefined) {

            window.updateTokenInterval = setInterval(() => {
                this.props.authoriseUserAction.updateToken()
            }, (1800) * 1000)
        }

        if (this.props.hideHeader !== true) {
            window.equPortfolioPageInterval = setInterval(this.refreshPage, 1000);
            this.props.actionViewEquipmentPortfolio.loadEquipmentPortfolio('',true);
        } else if (
            this.props.hideHeader === true &&
            this.props.disableAutoRefresh !== true
        ) {

            window.equPortfolioPageInterval = setInterval(
                this.refreshPageEquipAccTofacility,
                1000
            );
        }

        setTimeout(function () {
            $(".loaderPosition").addClass("hidden");
        }, 2000);
    }

    componentWillUnmount() {
        clearInterval(window.equPortfolioPageInterval);
    }

    reload = () => {
        $(".loaderPosition").removeClass("hidden");

        this.props.actionViewEquipmentPortfolio.loadEquipmentPortfolio('',true);
    };

    refreshPage = () => {
        // Creating the open Facility list
        const facilityIdList = []
        let listOnly = true
        for (const i in this.props.portfolio) {
            if (this.props.portfolio[i].iconStatus === true) {
                facilityIdList.push(this.props.portfolio[i].id)
                listOnly = false
            }
        }
     
        this.props.actionViewEquipmentPortfolio.loadEquipmentPortfolio(facilityIdList.toString(),listOnly);
    };

    refreshPageEquipAccTofacility = () => {

        this.props.actionViewPowerMetering.loadNodeAccToFacility(
            this.props.token,
            this.props.facilityId
        );
    };

    logOff = () => {
        this.props.logOffAction.logOut();
    };

    _changeIconStatus = (site) => {
        this.props.actionViewEquipmentPortfolio.changeIconStatus(site);
    };

    getUpdatedLink() {

        fetch(config.baseURL + "sessions", {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${this.props.token}`
            })
        }).then(response => {
            response.json()
                .then(data => {
                    window.location.href = (data.data.featureList[0].value)
                })
        })
    }

    render() {
        console.log("this.props.portfolio", this.props.portfolio, this.props.dataequipmentOfFacility, this.props.hideHeader);
        let ReactToastr = require("react-toastr");
        let { ToastContainer } = ReactToastr;
        let ToastMessageFactory = React.createFactory(
            ReactToastr.ToastMessage.animation
        );

        if (
            this.props.displayMessage !== undefined &&
            Math.floor(Date.now() / 1000) - this.props.displayMessage.timestamp < 2
        ) {
            this.refs.container.info("", this.props.displayMessage.message, {
                timeOut: 5000,
                preventDuplicates: true,
                closeButton: true
            });
        }
        console.log("this.props.Full", this.props.portfolio);
        

        return (
            <div className="reportPage">
                <ToastContainer
                    ref="container"
                    toastMessageFactory={ToastMessageFactory}
                    className="toast-top-right"
                />

                <div className="wrapperH">
                    {this.props.hideHeader === undefined ||
                        this.props.hideHeader !== true ? (
                        <div className="loginH clearfix">
                            <div className="logosmallH">
                                <a href="javascript:void(0);">&nbsp;</a>
                            </div>
                            <div className="logoff ">
                                <div className="three_line" onClick={this.toggleOptions.bind(this)}>&nbsp;</div>
                            </div>

                            {/*                                 
                                <div className="logoff">
                                    <span className="logoffcircle">&nbsp;</span>
                                    <span onClick={this.logOff}>Log off</span>
                                </div> */}

                        </div>
                    ) : (
                        ""
                    )}
                    <div>
                        {this.state.showOptions ?
                            <div className="options_menu">
                                <Options />
                            </div>
                            : null}
                    </div>


                    {/*Display division when there is some announcement in the session tab except for "none" */}
                    <div>
                        {(global.sessions.announcement !== "none" && global.sessions.announcement_flag) ?
                            (<div className="announcement_box bdRadius spmb30">
                                <div className="clearfix wid100per informationHeader">
                                    <div className="newEqwH2 announcement_font">
                                        {global.sessions.announcement}</div>
                                    <div className="newEqwH1 mr10">
                                        <button className="announcement_button" onClick={this.togglePopup.bind(this)}>X</button>
                                    </div>
                                </div>
                            </div>
                            )
                            : null
                        }
                    </div>

                    {/*Display division when there is some data in featureList in the session tab and also the property tab in featurelist should be having analyticsUrl value*/}
                    <div>
                        {(global.sessions.featureList_property == "analyticsUrl" && global.sessions.analyticsLink_flag) ?
                            (<div className="analyticsLink_box bdRadius spmb30">
                                <div className="clearfix wid100per informationHeader">
                                    <div className="newEqwH2">
                                        <a href="javascript:void(0);" onClick={this.getUpdatedLink.bind(this)} className="analyticsLink_font desktop_size">
                                            Access Volta Analytics
                                        </a>
                                        <div className="analytics_msg mobile_size">
                                            Access Volta Analytics Desktop Only
                                        </div>
                                    </div>
                                </div>
                            </div>

                            )
                            : null
                        }
                    </div>

                    <div className="clearfix">
                        <div className="blueBoxH bdRadius">
                            <div className="titleH">
                                {this.props.hideHeader === undefined ||
                                    this.props.hideHeader !== true ? (
                                    <div>
                                        <div className="text-left left">EQUIPMENT PORTFOLIO</div>

                                        <div className="eqRightMar">

                                            {/*pulling data from global variable declared in LoginPage.js*/}
                                            {global.sessions.customerName}
                                        </div>

                                    </div>

                                ) : (
                                    <div className="marLeft">
                                        <div className="text-left left">
                                            {this.props.facilityName}
                                        </div>
                                        <br />
                                        <div>Individual Equipment</div>
                                    </div>
                                )}
                                {// WE need to show the updated time again then we need to revirt the code form git.
                                    this.props.hideHeader !== true ? null : null}
                                <div className="clearfix"></div>
                            </div>

                            <div className="equListH marTop">
                                <ul>
                                    {
                                        
                                        this.props.portfolio.sort((a, b) => {
                                            if (a.facilityName > b.facilityName) {
                                                return 1
                                            }else{return -1}
                                        }).map(site => {
                                            // console.log("site", site);
                                            return (
                                                <div>
                                                    <div className="blueBoxH bdRadius spmb30">
                                                        <div className="clearfix wid100per informationHeader">
                                                            <div
                                                                className={!site.iconStatus ? "equTriangleIcon" : "equTriangleDownIcon"}
                                                                onClick={() => this._changeIconStatus(site)}>
                                                                <div className="eqLeftMar">
                                                                    {site.facilityName}
                                                                </div>
                                                            </div>
                                                            <div className="rightSec">
                                                                {site.siteName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        site.iconStatus && site.locations.sort((a, b) => {
                                                            if (a.name > b.name) {
                                                                return 1
                                                            }else{return -1}
                                                        }).map((location, i) => (
                                                            <EquipmentKW
                                                                key={i}
                                                                node={location}
                                                                hideHeader={false}
                                                                noReloadPowerMetering={this.props.hideHeader}
                                                                action={
                                                                    this.props.actionViewPowerMetering
                                                                        .loadPowerMetering
                                                                }
                                                                actionToRefreshingPowerMetering={
                                                                    this.props.refreshingAction
                                                                }
                                                            />
                                                        ))
                                                    }

                                                </div>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state.application;
};

const mapDispatchToProps = dispatch => {
    return {
        actionViewEquipmentPortfolio: bindActionCreators(
            actionViewEquipmentPortfolio,
            dispatch
        ),
        actionViewPowerMetering: bindActionCreators(
            viewPowerMeteringAction,
            dispatch
        ),
        authoriseUserAction: bindActionCreators(authoriseUserAction, dispatch),
        logOffAction: bindActionCreators(logOffAction, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EquPortfolioPage);
