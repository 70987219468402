export default {
    datapoint_precision: 2,
    //baseURL: ' http://portal-aws.voltainsite.com:8811/api/',
    //baseURL: 'https://portal.voltainsite.com/api/',
    // baseURL: `${process.env.REACT_APP_PORTAL_API_URL}/api/`,
    baseURL: "https://portal.voltastaging.com/api/",
    // baseURL: 'https://portal.voltastaging.com/api/',
    // applicationUrlForResetPassword: ' https://staging.voltainsite.com/development/#/resetPassword?reset={TOKEN}',
    // applicationUrlForResetPassword: `https://${window.location.host}/#/resetPassword?reset={TOKEN}`,
    applicationUrlForResetPassword: " https://portal.voltastaging.com/#/resetPassword?reset={TOKEN}",
    harmonicChartColumnWidth: 4,
    trendInitialDays: 8,
    trendInitialDaysForPowerMetering: 1,
    trendMaxDays: 365,
    subNodeTrendInitial: 1,
    subNodeTrendMaxDays: 30,
    // externalToken: process.env.REACT_APP_AUTH_TOKEN,
    externalToken: "AD28DDF-47847-F53E5462D9AD4F27B6B411820DA2B0B6",
    encrypt_key_1: "my secret key part one is #12345!",
    encrypt_key_2: "my secret key part two is #67890!",
    // trendFields: [
    //     {
    //         name:'Voltage',
    //         value:'voltage'
    //     },
    //     {
    //         name:'Current',
    //         value:'current'
    //     },
    //     {
    //         name:'HP',
    //         value:'hp'
    //     },
    //     {
    //         name:'Line Frequency',
    //         value:'lineFrequency'
    //     },
    //     {
    //         name:'Power Factor',
    //         value:'powerFactor'
    //     },
    //     {
    //         name:'RPM',
    //         value:'rpm'
    //     },
    //     {
    //         name:'Torque',
    //         value:'torque'
    //     },
    //     {
    //         name:'VaLCValue',
    //         value:'vaLCValue'
    //     },
    //     {
    //         name:'VbLCValue',
    //         value:'vbLCValue'
    //     },
    //     {
    //         name:'VcLCValue',
    //         value:'vcLCValue'
    //     },
    //     {
    //         name:'VDivF',
    //         value:'vDivF'
    //     },
    //     {
    //         name:'Voltage Imbalance',
    //         value:'voltageImbalance'
    //     },
    //     {
    //         name:'Current Imbalance',
    //         value:'currentImbalance'
    //     },
    //     {
    //         name:'Current THD',
    //         value:'currentThd'
    //     },
    //     {
    //         name:'Voltage THD',
    //         value:'voltageThd'
    //     },
    //     {
    //         name:'Power kW',
    //         value:'powerKw'
    //     },
    //     {
    //         name:'Power Factor A',
    //         value:'powerFactorA'
    //     },
    //     {
    //         name:'Power Factor B',
    //         value:'powerFactorB'
    //     },
    //     {
    //         name:'Power Factor C',
    //         value:'powerFactorC'
    //     },
    //     {
    //         name:'Energy kwh',
    //         value:'energyKwh'
    //     },
    //     {
    //         name:'Ground Induced Signal Voltage',
    //         value:'groundInducedSignalVoltage'
    //     },
    //     {
    //         name:'Ground Induced Signal Current',
    //         value:'groundInducedSignalCurrent'
    //     },
    //     {
    //         name:'Field Current',
    //         value:'fieldCurrent'
    //     },
    //     {
    //         name:'Field Voltage',
    //         value:'fieldVoltage'
    //     },
    //     {
    //         name:'Raw Power Kw',
    //         value: 'rawPowerKw'
    //     }
    // ]
};
