/* eslint-disable */
import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as trendAnalysis from '../../actions/trendAnalysis';
import TopNavigation from "../common/topNavigation";
import * as logOffAction from '../../actions/logOffAction';
import Mapping from './Mapping';
import SecTopHeader from "../common/SecTopHeader";
import * as fc from "d3fc";
import BuildLineChart from "../../chart/line";
let chartOne = null, chartTwo = null, chartThree = null, chartFour = null;
import config from '../../api/config';
import Options from "../common/more_options";                            


class TrendingAnalysisPageNew extends React.Component {

    constructor(props) {
        super(props);
        console.log("props.application", props.application);
        if (props.application.trendData && props.application.trendData.historyFieldList && props.application.trendData.historyFieldList.length > 0) {
            this.selectedDPOne = props.application.trendData.historyFieldList[0];
            this.selectedDPTwo = props.application.trendData.historyFieldList[1];
            this.selectedDPThree = props.application.trendData.historyFieldList[2];
            this.selectedDPFour = props.application.trendData.historyFieldList[3];
        }
        this.pageIsLoading = true;
        this.selectedEquipmentObjOne;
        this.selectedEquipmentObjTwo;
        this.selectedEquipmentObjThree;
        this.selectedEquipmentObjFour;
        this.firstChartNodeStauts = false;
        this.secChartNodeStauts = false;
        this.thirdChartNodeStauts = false;
        this.fourthChartNodeStauts = false;
        this.state = {
            showOptions: false,
            
            chartOneSerialNumber: '',
            chartOneSecondarySerialNumber: '', 
            
            chartTwoSerialNumber: '',
            chartTwoSecondarySerialNumber: '' ,
            
            chartThreeSerialNumber: '',
            chartThreeSecondarySerialNumber: '', 
            
            chartFourSerialNumber: '',
            chartFourSecondarySerialNumber: '' ,


        };
    }
    
    toggleOptions() {
        this.setState({ showOptions: !this.state.showOptions });
    }

    componentDidMount() {
        if (this.props.application.token === "") {
            this.props.history.push("/equipment-portfolio")
        } else {
            if (window.equPortfolioPageInterval !== undefined) {
                clearTimeout(window.equPortfolioPageInterval);
            }

            if (window.powerMeterAdminPageInterval !== undefined) {
                clearTimeout(window.powerMeterAdminPageInterval);
            }

            if (this.props.application.trendData.chartData && this.props.application.trendData.chartData.hp === undefined) {
                this.props.actionTrendAnalysis.RedirectToEquipmentPortfolio();
            }

            setTimeout(function () {
                this.pageIsLoading = false;
            }.bind(this), 0);
        }
    }

    getUpdatedLink() {
        fetch(config.baseURL + "sessions", {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${this.props.application.token}`
            })
        }).then(response => {
            console.log(response)
            response.json()
                .then(data => {
                    window.location.href = (data.data.featureList[0].value)
                })
        })
    }
    
    componentWillUnmount() {
        try {
            if (this.props.application.comingSource === "subNodePage") {
                try {
                    this.props.logOffAction.clearSubNodeCondition();
                } catch (e) {
                    console.log('Error', e);
                }

            }

            try {
                window.requestGetTrendDataAll.abort();
            } catch (e) {
                console.log('Error while window.requestGetTrendDataAll.abort');
            }

            try {
                window.requestGetSpecificTrendData.abort();
            } catch (e) {
                console.log('Error while window.requestGetSpecificTrendData.abort');
            }
        } catch (e) {
            console.log('Error', e);
        }
    }

    logOff = () => {
        try {
            window.requestGetTrendDataAll.abort();
        } catch (e) {
            console.log("Already aborted");
        }
        this.props.logOffAction.logOut();
    };

    render() {
        return (
            <div>
                <div className="reportPage">
                    <div className="wrapperHW">
                        <div className="wrapperH paddingZero">
                            <div className="loginH clearfix">
                                <div className="logosmallH">
                                    <a href="#">&nbsp;</a>
                                </div>
                                <div className="logoff ">
                                <div className="three_line" onClick={this.toggleOptions.bind(this)}>&nbsp;</div>
                            </div>
                        </div>
                        <div>
                            {this.state.showOptions ?
                                <div className="options_menu_trend_page">
                                    <Options />
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className="clearfix">

                            <div className="wrapperH paddingZero">
                                {
                                    this.props.application.comingSource === "subNodePage" ?
                                        <TopNavigation activeNode={5} source={"subNodePage"} hideInfo={true}/> :
                                        <TopNavigation activeNode={5}/>
                                }


                            </div>
                            <div className="blueBoxH bdRadius spmb30">
                                <div className="titleH clearfix" style={{'borderBottom': 'none'}}>
                                    <div className="main-chart-height" style={{'position':'relative'}}>
                                        <img src='/trending-blur.png' style={{'width': '100%', 'height':'100%'}}/*className='main-chart-height'*//>
                                        <div style={{
                                            'position':'absolute',
                                            'top': '50%',
                                            'left': '50%',
                                            'transform': 'translate(-50%, -50%)',
                                            'fontSize': '20px'
                                            }}>
                                            <p>
                                                {"Trending Data has moved to "}                            
                                                <a href="javascript:void(0);" onClick={this.getUpdatedLink.bind(this)} className="analyticsLink_font desktop_size">
                                                Volta Analytics
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        actionTrendAnalysis: bindActionCreators(trendAnalysis, dispatch),
        logOffAction: bindActionCreators(logOffAction, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrendingAnalysisPageNew);
