import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import * as logOffAction from "../../actions/logOffAction";
import { bindActionCreators } from "redux";

import '../../css/custom.css';

class Options extends Component {
    
    logOff = () => {
        this.props.logOffAction.logOut();
    };

    render() {
        
        return (
            <div className='more_option'>
                <ui>
                    <li className = "user_icon">&nbsp;</li>
                    <li className= "options_userId">{global.sessions.userId}</li>    
                    <br></br>
                    <li><Link className='options_link' to="/recoverPassword">Change Password</Link></li>
                    <li><Link className='options_link' onClick={this.logOff}>Log out</Link></li>
                    <br></br>
                    <li>Customer Support:</li>
                    <a href='mailto:support@voltainsite.com' className="more_option_support_email">support@voltainsite.com</a>
                </ui>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return state.application;
};

const mapDispatchToProps = dispatch => {
    return {
        logOffAction: bindActionCreators(logOffAction, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Options);
